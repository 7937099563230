import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import CtaDefault from './CtaDefault'
import CtaQuote from './CtaQuote'
import CtaDownload from './CtaDownload'

export default function CtaShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: CtaDefault,
    quote: CtaQuote,
    download: CtaDownload
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
