import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroDefault({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  return (
    <section>
      <div className="container-right">
        <div className="relative xl:mx-44 xl:pl-20">
          <LossePlaatjie src={fields.background} />
        </div>
      </div>
    </section>
  )
}
