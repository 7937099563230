import { memo } from 'react'

function ChevronDouble({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={className}>
      <path
        fill="#fff"
        d="M13.1 19a1 1 0 01-.7-1.71l4.6-4.58a1 1 0 000-1.42l-4.6-4.58a.998.998 0 010-1.42 1 1 0 011.41 0l4.59 4.59a3 3 0 010 4.24l-4.59 4.59a1.001 1.001 0 01-.71.29z"
      ></path>
      <path
        fill="#fff"
        d="M6.1 19a1 1 0 01-.7-1.71L10.69 12 5.4 6.71a1 1 0 111.41-1.42l6 6a1.002 1.002 0 010 1.42l-6 6a1 1 0 01-.71.29z"
      ></path>
    </svg>
  )
}

export default memo(ChevronDouble)
