import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDownloads({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id={fields.sectionid || 'TextDownloads'} className="relative">
      <div className="container">
        <div className={`relative ${fields.containerwidth === 'small' ? 'xl:ml-44' : 'sm:ml-7'}`}>
          <Content className="parsecontent-sm pr-4 sm:py-14 sm:pr-20 xl:pl-20 xl:w-3/4 mx-auto children:!text-lg children-p:mb-4">
            {fields?.description}
          </Content>
        </div>
        {fields.link?.title && (
          <div className="mt-7 xl:ml-28">
            <LosseLink className="btn btn-metric-electrike" to={fields.link?.url || '/'}>
              {fields.link?.title}
            </LosseLink>
          </div>
        )}
      </div>
    </section>
  )
}
