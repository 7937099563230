export default function Facebook({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" fill="none" viewBox="0 0 47 47" className={className}>
      <path
        fill="#fff"
        d="M27.654 24.75l.556-3.62h-3.473v-2.348c0-.99.485-1.956 2.04-1.956h1.58v-3.082s-1.434-.244-2.803-.244c-2.86 0-4.73 1.734-4.73 4.872v2.759h-3.18v3.619h3.18v8.75h3.913v-8.75h2.917zM23.5 46C11.074 46 1 35.926 1 23.5h-2C-1 37.031 9.969 48 23.5 48v-2zM46 23.5C46 35.926 35.926 46 23.5 46v2C37.031 48 48 37.031 48 23.5h-2zM23.5 1C35.926 1 46 11.074 46 23.5h2C48 9.969 37.031-1 23.5-1v2zm0-2C9.969-1-1 9.969-1 23.5h2C1 11.074 11.074 1 23.5 1v-2z"
      ></path>
    </svg>
  )
}
