import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextVector({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextVector" className={`relative ${fields.bgcolor === 'grey' ? 'bg-metric-zangoose' : ''}`}>
      <div className={`${fields.imageposition === 'right' ? 'container-right' : 'container'} py-4 grid grid-cols-6`}>
        <div
          className={`col-span-6 mt-4 mb-6 sm:mb-0 ${
            fields.imageposition === 'right' ? 'order-first sm:order-last sm:-ml-24 sm:col-span-2' : 'order-first sm:col-span-3 ml-24'
          }`}
        >
          <LossePlaatjie src={fields.image} className="w-full h-full max-w-[200px] mx-auto sm:max-w-none" />
        </div>
        <div
          className={`col-span-6 relative  self-center sm:ml-6 lg:ml-10 ${
            fields.imageposition === 'right' ? 'order-first sm:col-span-4' : 'order-last sm:col-span-3'
          }`}
        >
          <div className="absolute h-[100%] w-[2px] left-0 bottom-0 bg-metric-qwilfish" />
          <Content className="parsecontent-md parsecontent-h pl-4 sm:pl-16 sm:py-7">{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
