import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FormContact from './FormContact'
import FormContactHero from './FormContactHero'
import FormVacancy from './FormVacancy'

const FormShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    contact: FormContact,
    contacthero: FormContactHero,
    vacancy: FormVacancy
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default FormShell
