import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import SliderDefault from './SliderDefault'
import SliderCta from './SliderCta'

export default function SliderShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: SliderDefault,
    cta: SliderCta
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
