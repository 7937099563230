/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import { LosseLink, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import ChevronDouble from '~/components/svg/ChevronDouble'
import Active from '~/components/svg/Active'

function Menu({ totalPosts }: { totalPosts: number }) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = React.useState(-1)
  const { pathname } = useLocation()

  function handleOutsideClick() {
    setOpen(-1)
  }

  useEffect(() => {
    if (open) {
      setOpen(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        return (
          <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="px-4 xl:px-6" key={index}>
            <div className="flex flex-wrap relative items-center">
              {edge?.link?.url?.includes('kenniscentrum') && (
                <div className="absolute top-4 left-40 lg:left-[unset] lg:top-3 lg:-right-4 text-white bg-metric-electrike font-semibold rounded-full text-sm w-5 flex items-center justify-center h-5">
                  {totalPosts - 2}
                </div>
              )}
              {edge?.dropdown && open === index && (
                <div className="absolute -left-5 top-[1.85rem]">
                  <Active />
                </div>
              )}
              <LosseLink
                className={`text-xl font-semibold text-white aria-current-page:text-metric-electrike hover:underline lg:text-base py-6`}
                to={edge?.link?.url || '/'}
              >
                {edge?.link?.title}
              </LosseLink>
              {edge?.dropdown && open === index && (
                <div className="absolute flex w-full bottom-0 lg:justify-center">
                  <div className="border-solid border-b-white border-b-8 border-x-transparent border-x-8 border-t-0" />
                </div>
              )}
              <div className="lg:hidden">{edge?.dropdown && <div className="pb-3 rotate-90">{/* <ChevronDouble /> */}</div>}</div>
            </div>

            <AnimatePresence>
              {edge?.dropdown && open === index && <HeaderSubMenu fields={edge?.dropdown} closeSubmenu={handleOutsideClick} />}
            </AnimatePresence>
          </motion.li>
        )
      })}
    </>
  )
}

export default Menu
