export default function EmailRounded({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" fill="none" viewBox="0 0 47 47" className={className}>
      <path
        fill="#fff"
        d="M23.5 46C11.074 46 1 35.926 1 23.5h-2C-1 37.031 9.969 48 23.5 48v-2zM46 23.5C46 35.926 35.926 46 23.5 46v2C37.031 48 48 37.031 48 23.5h-2zM23.5 1C35.926 1 46 11.074 46 23.5h2C48 9.969 37.031-1 23.5-1v2zm0-2C9.969-1-1 9.969-1 23.5h2C1 11.074 11.074 1 23.5 1v-2z"
      ></path>
      <g fill="#fff" clipPath="url(#clip0_573_832)">
        <path d="M21.997 23.887a2.13 2.13 0 003.006 0l6.842-6.843a2.125 2.125 0 00-1.97-1.335h-12.75a2.125 2.125 0 00-1.97 1.335l6.842 6.843z"></path>
        <path d="M26.005 24.889a3.547 3.547 0 01-5.01 0L15 18.896v12.396h17V18.896l-5.995 5.993z"></path>
      </g>
      <defs>
        <clipPath id="clip0_573_832">
          <path fill="#fff" d="M0 0H17V17H0z" transform="translate(15 15)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
