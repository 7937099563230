import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Crystal from '~/components/svg/Crystal'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

// Share
import { WhatsappShareButton, FacebookShareButton, EmailShareButton } from 'react-share'
import Whatsapp from '~/components/svg/Whatsapp'
import Facebook from '~/components/svg/Facebook'
import EmailRounded from '~/components/svg/EmailRounded'

export default function BannerPost({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData>()

  const isVacancy = page.contentType?.node?.name === 'vacancy'

  return (
    <section id="BannerPost" className="relative bg-metric-hariyama text-white overflow-hidden lg:min-h-[600px] mb-24">
      <Crystal type="white" className="absolute -bottom-36 -left-36 opacity-40" />
      <div className="pt-24 lg:pt-44 pb-2">
        <div className="container mb-6">
          <Breadcrumbs />
        </div>
        <div className="relative container-right grid grid-cols-2 pb-12">
          <div className="relative col-span-2 lg:col-span-1">
            <div className="relative z-10 lg:-mr-28 lg:-mt-36">
              <LossePlaatjie src={fields.image} className="relative w-full h-full max-h-[350px] lg:max-h-[600px]" />
            </div>
            <div className="relative flex items-center py-6 children:mr-4 children:!py-1 children:!overflow-visible mt-14 lg:mt-0">
              <span>Deel dit bericht</span>
              <WhatsappShareButton title={page.title || ''} url={`https://www.metric-it.nl${page.uri}`}>
                <Whatsapp className="rounded-full" />
                <span className="hidden">Whatsapp</span>
              </WhatsappShareButton>
              <FacebookShareButton title={page.title || ''} url={`https://www.metric-it.nl${page.uri}`}>
                <Facebook className="rounded-full" />
                <span className="hidden">Facebook</span>
              </FacebookShareButton>
              <EmailShareButton title={page.title || ''} url={`https://www.metric-it.nl${page.uri}`}>
                <EmailRounded className="rounded-full" />
                <span className="hidden">Email</span>
              </EmailShareButton>
            </div>
          </div>

          <div className="relative col-span-2 lg:col-span-1 lg:order-first self-center">
            <Crystal type="white" className="absolute opacity-40 bottom-24 right-28 w-[270px] h-[312px]" />
            <h1 className="text-3xl lg:text-5xl uppercase font-extrabold lg:pr-48 pb-12 lg:pb-24">{page.title}</h1>
            {isVacancy && (
              <button onClick={() => document.getElementById('form-vacancy')?.scrollIntoView()} className="btn btn-metric-electrike">
                Solliciteer direct
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
