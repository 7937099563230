import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex elements
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import GridShell from './Grid/GridShell'
import SliderShell from './Slider/SliderShell'
import HeroShell from './Hero/HeroShell'
import CtaShell from './Cta/CtaShell'
import GoogleMaps from './GoogleMaps'
import CookiePolicy from './CookiePolicy/CookiePolicy'
import Contentstable from './Contentstable/Contentstable'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Grid`]: GridShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Hero`]: HeroShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_GoogleMaps`]: GoogleMaps,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_CookiePolicy`]: CookiePolicy,
    [`${prefix}_Flex_Contentstable`]: Contentstable,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
