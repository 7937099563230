import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Widget } from '@typeform/embed-react'
import { LosseLink } from '@ubo/losse-sjedel'

export default function TextTypeform({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id={fields.sectionid || 'TextTypeform'} className="relative bg-metric-hariyama overflow-hidden mb-10">
      <div className="container-right py-14 sm:py-20 relative">
        <div className="grid gap-10 lg:gap-20 relative z-10">
          <div className="flex items-center max-w-screen-md">
            <div>
              <Content className="content text-white children-h2:mb-6 children-h2:text-4xl children-h2:uppercase children-h2:font-black">
                {fields.description}
              </Content>
              {fields.link && (
                <LosseLink className="btn btn-metric-electrike mt-6" to={fields.link?.url || '/'} target={fields.link?.target || undefined}>
                  {fields.link?.title}
                </LosseLink>
              )}
            </div>
          </div>
          {fields?.typeform && (
            <div>
              <Widget id={fields?.typeform as string} className="w-full h-[875px] mx-auto" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
