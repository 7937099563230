import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Crystal from '~/components/svg/Crystal'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import ChevronDouble from '~/components/svg/ChevronDouble'
import Download from '~/components/svg/Download'
import ContentstableGrid from '~/components/elements/ContentstableGrid'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { hasContentstable } = useLoaderData()

  return (
    <section id="BannerDefault" className="relative lg:h-[90vh] xl:max-h-[750px] lg:mb-[21rem]">
      <div className="relative max-w-[2200px] h-full mx-auto">
        <div className="relative overflow-hidden h-full w-full">
          <div className="absolute right-0 bottom-36">
            <Crystal type="white" className="w-[264px] h-[308px]" />
          </div>
          <div className="absolute right-6 -bottom-80">
            <Crystal type="green" className="" />
          </div>
        </div>

        <LossePlaatjie
          loading="eager"
          className="relative lg:absolute top-0 left-0 -z-10 h-full w-full object-cover object-top"
          src={fields.background}
          maxwidth={1999}
        />
      </div>

      <div className="container-right">
        <div className="relative lg:absolute lg:-bottom-[17rem] -mt-7 lg:-mt-0">
          <ContentstableGrid>
            <div
              className={`relative xl:min-w-[880px] ${fields?.bgcolor === 'hariyama' ? 'bg-metric-hariyama' : 'bg-metric-qwilfish'
                } text-white p-6 md:p-10 lg:px-16 lg:pt-20 lg:pb-10 ${hasContentstable ? 'lg:w-[90%]' : 'lg:w-3/4'}`}
              id="BannerDefault-block"
            >
              <LossePlaatjie src={fields.icon} className="absolute right-6 top-6 z-[10] opacity-10" />
              <Breadcrumbs />
              <Content className="text-light parsecontent-md lg:children-p:pr-16 lg:mt-6 children-h2:text-lg children-h2:font-bold children-h2:text-metric-electrike">
                {fields?.description}
              </Content>
              <div className="relative mt-5 z-10 w-full md:w-auto">
                {fields.link?.title && (
                  <LosseLink className="btn btn-metric-electrike inline-flex items-center md:mr-5 mb-4 lg:mb-0 w-full md:w-auto" to={fields.link.url || '/'}>
                    {fields.link.title.toLowerCase().includes('download') && <Download className="mr-3" />}
                    <span>{fields.link.title}</span>
                  </LosseLink>
                )}
                {fields.landingCta?.title && (
                  <LosseLink className="btn btn-white w-full md:w-auto" to={fields.landingCta?.url || '/'}>
                    {fields.landingCta?.title}
                  </LosseLink>
                )}
              </div>
              {fields.scrollto && (
                <div
                  className="hidden relative z-[10] md:flex justify-end animate-bounce"
                  role="button"
                  onClick={() => document.getElementById(fields.scrollto || '')?.scrollIntoView({ behavior: 'smooth' })}
                >
                  <ChevronDouble className="rotate-90" />
                </div>
              )}
            </div>
          </ContentstableGrid>
        </div>
      </div>
    </section>
  )
}
