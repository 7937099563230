import Content from '~/components/elements/Content'
import ContentstableGrid from '~/components/elements/ContentstableGrid'
import Quote from '~/components/svg/Quote'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaQuote({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section id="CtaQuote" className="relative">
      <div className="container-right">
        <ContentstableGrid>
          <div className="relative flex justify-around px-4 sm:pr-52 sm:pl-24 pt-28 pb-6 sm:pb-28 bg-metric-zangoose">
            <h2 className="font-extrabold opacity-10 text-6xl absolute right-0 top-2">QUOTE</h2>
            <Quote className="-mt-10" />
            <Content className="parsecontent-xl">{fields.description}</Content>
            <Quote className="rotate-180 mt-20 lg:mt-10 relative lg:left-4" />
          </div>
        </ContentstableGrid>
      </div>
    </section>
  )
}
