import { memo } from 'react'

function Crystal({ className = '', type = 'green' }: { className?: string; type?: 'green' | 'blue' | 'white' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="700"
      height="810"
      fill="none"
      viewBox="0 0 700 810"
      className={`${className} pointer-events-none`}
    >
      <path
        stroke={type === 'green' ? '#5ACD8F' : type === 'white' ? '#FFF' : '#12478B'}
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M697 605.498V204.502L350.004 4 3 204.502v400.996L350.004 806 697 605.498z"
      />
    </svg>
  )
}

export default memo(Crystal)
