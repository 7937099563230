import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import { motion } from 'framer-motion'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import ContentstableGrid from '~/components/elements/ContentstableGrid'

export default function TextAccordion({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const [active, setActive] = useState(0)

  return (
    <section id={clsx(fields?.sectionid ? fields.sectionid : 'TextAccordion')} className={clsx(fields.bgcolor === 'grey' && 'bg-metric-zangoose', 'py-10 lg:py-20')}>
      <div className={`container-right ${fields.bgcolor === 'hariyama' ? 'text-white' : 'text-black'}`}>
        <ContentstableGrid className={clsx(fields?.containerwidth === 'small' && 'md:w-3/4')}>
          <div
            className={`sm:ml-7 relative pr-4 p-8 pt-0
            ${fields.bgcolor === 'hariyama' ? 'bg-metric-hariyama xl:pl-64' : 'bg-transparent xl:pl-16'}`}
          >
            {(fields.bgcolor === 'transparent' || fields.bgcolor === 'grey') && (
              <div className="absolute h-full w-[2px] right-0 sm:left-0 bottom-0 bg-metric-qwilfish" />
            )}
            <Content className={`relative ${fields.bgcolor === 'hariyama' ? 'check-light' : ''} parsecontent-sm children:mb-4`}>
              {fields?.description}
            </Content>
          </div>
          <div className="grid grid-cols-1 gap-6 lg:gap-10 mt-14 lg:mt-20">
            {fields.items?.map((item, index) => (
              <div key={index} className="bg-white p-6 lg:p-14">
                <button
                  type="button"
                  onClick={() => {
                    setActive(index)
                  }}
                  className="flex items-center justify-between w-full"
                >
                  <div className="flex items-center">
                    <LossePlaatjie src={item?.icon} className="lg:w-16 lg:h-16 w-10 h-10 object-contain mr-4" />
                    <h3 className="text-sm md:text-lg lg:text-2xl text-metric-hariyama uppercase font-black">{item?.title}</h3>
                  </div>
                  <motion.div
                    animate={active === index ? 'open' : 'closed'}
                    variants={{
                      open: { rotate: 0 },
                      closed: { rotate: 180 }
                    }}
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.38371 13.9866L6.61621 12.2191L13.2325 5.60282C13.7013 5.13414 14.3371 4.87085 15 4.87085C15.6629 4.87085 16.2986 5.13414 16.7675 5.60282L23.3837 12.2191L21.6162 13.9866L15 7.37532L8.38371 13.9866Z"
                        fill="#12478B"
                      />
                      <path
                        d="M8.38371 22.7369L6.61621 20.9694L14.1162 13.4694C14.3506 13.235 14.6685 13.1034 15 13.1034C15.3314 13.1034 15.6493 13.235 15.8837 13.4694L23.3837 20.9694L21.6162 22.7369L15 16.1256L8.38371 22.7369Z"
                        fill="#12478B"
                      />
                    </svg>
                  </motion.div>
                </button>
                <motion.div
                  animate={active === index ? 'open' : 'closed'}
                  variants={{
                    open: { height: 'auto', opacity: 1 },
                    closed: { height: 0, opacity: 0 }
                  }}
                  className="overflow-hidden"
                >
                  <Content className="children-p:mb-4 last:children-p:mb-0 mt-8">{item?.description}</Content>
                </motion.div>
              </div>
            ))}
          </div>
        </ContentstableGrid>
      </div>
    </section>
  )
}
