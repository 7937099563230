import type { Post } from '~/graphql/types'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import type { ReactElement } from 'react'
import { useState } from 'react'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import ArrowDiamond from '~/components/svg/ArrowDiamond'
import Content from '~/components/elements/Content'
import useMedia from 'use-media'

export default function Latest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const __typename = fields.posts?.edges![0]?.__typename

  let component: ReactElement
  switch (__typename?.toLowerCase()) {
    case 'case':
      component = <BlogPost fields={fields} />
      break
    default:
      component = <BlogPost fields={fields} />
      break
  }

  return <section id="Latest">{component}</section>
}

function BlogPost({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const mobile = useMedia({ maxWidth: 575 })

  let postsByCategory = fields.posts?.edges

  if (fields.category) {
    postsByCategory = fields.posts?.edges?.filter((edge) => {
      const post = edge?.node as Post

      return fields.category![0]?.databaseId === post.categories?.edges![0]?.node?.databaseId
    })
  }

  return (
    <div className="relative">
      <LossePlaatjie src={fields.background} className="absolute left-0 right-0 -bottom-32 w-full h-full" />
      <div className="relative py-8 sm:pt-28 sm:pb-14">
        <Content className="parsecontent-lg text-metric-hariyama mb-9">{fields.description}</Content>
        <Slider
          className="!ml-8 lg:!ml-24"
          mousewheel={{
            sensitivity: 100,
            forceToAxis: true
          }}
          direction="horizontal"
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 30
            },
            1600: {
              slidesPerView: 4.5,
              spaceBetween: 40
            },
            1900: {
              slidesPerView: 4.5,
              spaceBetween: 40
            }
          }}
        >
          {postsByCategory?.map((edge, index) => {
            const post = edge?.node as Post
            const open = currentIndex === index

            if (post.title?.toLowerCase().includes('fast lane benelux')) {
              return null
            }
            if (post.title?.toLowerCase().includes('vibe group')) {
              return null
            }

            return (
              <Slide key={index} className="relative group !h-auto">
                <motion.div onClick={() => setCurrentIndex(index)} onHoverStart={() => setCurrentIndex(index)}>
                  <LosseLink to={post.uri || '/'}>
                    <LossePlaatjie src={post.recap?.thumbnail} className="h-[300px] lg:h-[534px] object-cover w-full" />
                    <motion.div
                      className="absolute bottom-0 bg-metric-qwilfish bg-opacity-70 sm:bg-opacity-100 px-4 py-3 sm:py-8 sm:px-10 text-white w-full"
                      initial={{ y: '80%' }}
                      animate={open || mobile ? { y: '0%' } : { y: '90%' }}
                      transition={{
                        bounce: 0
                      }}
                    >
                      <LosseLink className="relative w-full h-full inline-block" to={post.uri || '/'}>
                        <ArrowDiamond className="absolute -top-12 right-0 sm:-right-6 sm:-top-20 w-[40px] h-[40px] hidden sm:block sm:w-auto sm:h-auto opacity-70 sm:opacity-100" />
                        <span className="block font-bold text-xs sm:text-lg">
                          {post.categories?.edges?.map((category) => `${category?.node?.name?.toUpperCase()}:`)}
                        </span>
                        <h3 className="font-bold text-sm sm:text-lg group-hover:underline">{post.title?.toUpperCase()}</h3>
                      </LosseLink>
                    </motion.div>
                  </LosseLink>
                </motion.div>
              </Slide>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
