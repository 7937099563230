import React from 'react'

// Components
import FlexContentHandler from './FlexContentHandler'

export interface FragmentProps {
  fields: any
}

const Fragment: React.FC<FragmentProps> = ({ fields }) => (
  <FlexContentHandler prefix="fragment_FlexFragment" flex={fields.element.flexContent.flex} />
)

export default Fragment
