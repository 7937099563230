import { useLocation } from '@ubo/losse-sjedel'
import { Fragment } from 'react'
import { LosseLink } from '@ubo/losse-sjedel'

export default function Breadcrumbs() {
  let crumbs = ['home']
  const { pathname } = useLocation()
  const splitPathnames = pathname.split('/').filter((p) => p !== '')

  splitPathnames.forEach((p) => crumbs.push(p))

  return (
    <div className="flex flex-wrap items-center my-3">
      {crumbs.map((crumb, index) => {
        let url: Array<string> = []
        if (index > 0) {
          url = crumbs
            .slice(index - index, index + 1)
            .filter((c) => c !== 'home')
            .map((c) => c)
        }

        if (url.includes('blog') || url.includes('case') || url.includes('nieuws')) {
          url[0] = 'kenniscentrum'
        }

        return (
          <Fragment key={crumb}>
            <LosseLink
              to={`/${url.join('/')}`}
              className={`text-base capitalize ${index > 0 && crumb ? 'hover:underline focus:opacity-50' : ''}`}
            >
              {crumb.replace(/-/g, ' ')}
            </LosseLink>
            {crumbs.length !== index + 1 && <Separator />}
          </Fragment>
        )
      })}
    </div>
  )
}

function Separator() {
  return (
    <div className="mx-3 h-2 w-2">
      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
        <path
          d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
