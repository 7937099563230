import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Email from '~/components/svg/Email'
import { LosseLink } from '@ubo/losse-sjedel'
import Phone from '~/components/svg/Phone'
import Breadcrumbs from '~/components/elements/Breadcrumbs'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormContact" className="relative">
      <div className="container-right grid grid-cols-3">
        <div className="-mt-7 lg:-mt-64 bg-metric-hariyama text-white p-4 sm:px-16 sm:py-12 col-span-3 md:col-span-2">
          <div className="relative mb-8">
            <Breadcrumbs />
          </div>
          <Content className="parsecontent-md mb-5">{fields?.description}</Content>
          <Form
            data={fields.form}
            generate={false}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => (
              <div>
                <Content>{content}</Content>
              </div>
            )}
            className="relative"
          >
            <>
              <div className="grid grid-cols-2 gap-x-7">
                <div className="col-span-2 sm:col-span-1">
                  <FormDuufGroup showIds={[4, 1]} />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <FormDuufGroup showIds={[9, 6]} />
                </div>
                <div className="col-span-2">
                  <FormDuufGroup showIds={[2]} />
                </div>
              </div>
              <FormDuufSubmit />
            </>
          </Form>
        </div>
        <div className="col-span-3 md:col-span-1 sm:p-20 mt-6 sm:mt-0">
          <Content className="mb-5 parsecontent-sm parsecontent-h">{fields.contactdata?.description}</Content>
          <div className="mb-8">
            <LosseLink to={fields.contactdata?.email?.url || '/'} className="flex items-center mb-2 hover:underline">
              <Email />
              <span className="ml-3">{fields.contactdata?.email?.title}</span>
            </LosseLink>
            <LosseLink to={fields.contactdata?.phone?.url || '/'} className="flex items-center mb-2 hover:underline">
              <Phone />
              <span className="ml-3">{fields.contactdata?.phone?.title}</span>
            </LosseLink>
          </div>
          <Content>{fields.contactdata?.descriptiontwo}</Content>
        </div>
      </div>
    </section>
  )
}
