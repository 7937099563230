export default function DiamondRight({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="209" height="362" fill="none" viewBox="0 0 209 362" className={className}>
      <g clipPath="url(#clip0_512_842)">
        <path fill="#fff" d="M0 120.16v240.99l208.32-120.28V0L0 120.16z"></path>
      </g>
      <defs>
        <clipPath id="clip0_512_842">
          <path fill="#fff" d="M0 0H208.32V361.15H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
