import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import ContentstableGrid from '~/components/elements/ContentstableGrid'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextList({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id={fields.sectionid || 'TextList'} className="relative">
      <div className={`container-right ${fields.bgcolor === 'hariyama' ? 'text-white' : 'text-black'}`}>
        <ContentstableGrid>
          <div
            className={`sm:ml-7 relative pr-4 sm:py-14 xl:pr-20 p-16  ${fields.bgcolor === 'hariyama' ? 'bg-metric-hariyama xl:pl-64' : 'bg-transparent xl:pl-24'
              }`}
          >
            {fields.bgcolor === 'transparent' && <div className="absolute h-full w-[2px] right-0 sm:left-0 bottom-0 bg-metric-qwilfish" />}
            <Content className={`relative ${fields.bgcolor === 'hariyama' ? 'check-light' : ''} parsecontent-sm children:mb-4`}>
              {fields?.description}
            </Content>
            {fields.link?.title && (
              <div className="mt-7">
                <LosseLink className="btn btn-metric-electrike" to={fields.link?.url || '/'}>
                  {fields.link?.title}
                </LosseLink>
              </div>
            )}
          </div>
        </ContentstableGrid>
      </div>
    </section>
  )
}
