export default function Download({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none" viewBox="0 0 18 19" className={className}>
      <g fill="#fff" clipPath="url(#clip0_557_75)">
        <path d="M9.024 14.75a2.243 2.243 0 001.592-.659l2.939-2.941-1.06-1.058-2.725 2.726L9.75.5h-1.5l.02 12.306-2.715-2.715-1.06 1.059 2.938 2.94a2.244 2.244 0 001.591.66z"></path>
        <path d="M16.5 12.5v3.75a.75.75 0 01-.75.75H2.25a.75.75 0 01-.75-.75V12.5H0v3.75a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0018 16.25V12.5h-1.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_557_75">
          <path fill="#fff" d="M0 0H18V18H0z" transform="translate(0 .5)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
