import { GoogleMap, Marker } from '@react-google-maps/api'
import { useCallback, useState } from 'react'

export default function GoogleMaps() {
  // const [map, setMap] = useState(null)

  // const onUnmount = useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  // const onLoad = useCallback(async function callback(map) {
  //   map.setOptions({
  //     disableDefaultUI: true
  //   })
  //   setMap(map)
  // }, [])

  // const center = {
  //   lat: 52.2220994,
  //   lng: 5.3098101
  // }

  // const containerStyle = {
  //   width: '100%',
  //   height: '500px'
  // }

  // const styles: any = []

  return (
    <iframe
      title="Metric IT"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.1398251210157!2d5.4641287520360775!3d52.16815117964964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6461e55eb2d6f%3A0x64c99929701bc4c!2sDe%20Wel%202j%2C%203871%20MV%20Hoevelaken!5e0!3m2!1snl!2snl!4v1658828739810!5m2!1snl!2snl"
      width="100%"
      height={500}
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
    // <GoogleMap
    //   mapContainerStyle={containerStyle}
    //   options={{
    //     styles: styles
    //   }}
    //   center={center}
    //   zoom={8}
    //   onLoad={onLoad}
    //   id="grossiersMap"
    //   onUnmount={onUnmount}
    // >
    //   <Marker position={{ lat: 52.2220994, lng: 5.3098101 }} icon="/metric.svg" />
    // </GoogleMap>
  )
}
