import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { ReactNode } from 'react'
import type { Component_Footer } from '~/graphql/types'
import Content from '../elements/Content'
import Brand from '../svg/Brand'
import LogoAlt from '../svg/LogoAlt'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer className="relative bg-metric-cinderace overflow-hidden">
      <div className="absolute -right-20 -bottom-40 pointer-events-none">
        <Brand />
      </div>
      <ul className="container-right grid grid-cols-5 gap-x-20 sm:gap-x-0 py-20">
        <li className="col-span-5 sm:col-span-2 text-white mb-10 sm:mb-0">
          <LogoAlt className="mb-6" />
          <Content className="parsecontent-md mb-6">{footer.quoteFooter?.description}</Content>
          <LosseLink
            to={footer.quoteFooter?.link?.url || ''}
            className="btn btn-metric-electrike w-1/2"
            target={footer?.quoteFooter?.link?.target || '_self'}
          >
            {footer.quoteFooter?.link?.title}
          </LosseLink>
          <LosseLink to="/cookieverklaring" className="block mt-6">
            Cookieverklaring
          </LosseLink>
          <LosseLink to="/privacyverklaring" className="block mt-2">
            Privacyverklaring
          </LosseLink>
        </li>
        {footer.menuFooter?.columns?.map((column, index) => (
          <li key={index} className="col-span-2 sm:col-span-1">
            <FooterTitle>{column?.title}</FooterTitle>
            <ul>
              {column?.menu?.map((edge, subIndex) => (
                <li key={subIndex} className={`text-white py-1 ${index === 0 && subIndex === 2 ? 'mb-7' : 'mb-0'}`}>
                  <LosseLink to={edge?.link?.url || ''} className="hover:underline">
                    {edge?.link?.title}
                  </LosseLink>
                </li>
              ))}
            </ul>
          </li>
        ))}
        <li className="col-span-5 sm:col-span-1 mt-10 sm:mt-0">
          <FooterTitle>{footer.contactFooter?.title}</FooterTitle>
          <div className="text-white">
            <Content className="mb-12">{footer.contactFooter?.address}</Content>
            <div className="mb-12">
              <LosseLink className="flex items-center hover:underline" to={footer.contactFooter?.email?.link?.url || ''}>
                <LossePlaatjie src={footer.contactFooter?.email?.icon} maxwidth={13} />
                <span className="ml-3 mt-1">{footer.contactFooter?.email?.link?.title}</span>
              </LosseLink>
              <LosseLink className="flex items-center hover:underline" to={footer.contactFooter?.phone?.link?.url || ''}>
                <LossePlaatjie src={footer.contactFooter?.phone?.icon} maxwidth={13} />
                <span className="ml-3 mt-1">{footer.contactFooter?.phone?.link?.title}</span>
              </LosseLink>
            </div>
            <LosseLink
              className="hover:underline"
              to={footer.contactFooter?.linkedin?.url || ''}
              target={footer?.contactFooter?.linkedin?.target || '_blank'}
            >
              {footer.contactFooter?.linkedin?.title}
            </LosseLink>
          </div>
        </li>
      </ul>
    </footer>
  )
}

function FooterTitle({ children }: { children: ReactNode }) {
  return <h2 className="text-lg sm:text-2xl font-bold text-metric-electrike mb-1 sm:mb-14">{children}</h2>
}
