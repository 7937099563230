import { memo } from 'react'

function Arrow({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="none" viewBox="0 0 14 12" className={className}>
      <path
        fill="#fff"
        d="M14 6a2 2 0 00-.59-1.4L9.12.3a1 1 0 10-1.41 1.42L11 5H1a1 1 0 000 2h10l-3.29 3.29a1 1 0 001.41 1.42l4.29-4.3A2 2 0 0014 6z"
      />
    </svg>
  )
}

export default memo(Arrow)
