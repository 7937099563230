import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import Pentagon from '~/components/svg/Pentagon'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderCta({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section id="SliderCta" className={`relative ${fields.showcta && fields.cta ? ' margin-tight' : ''} `}>
      {fields.showcta && fields.cta && <div className="absolute 3xl:hidden bg-metric-cinderace bottom-0 h-[35px] w-full" />}
      <div className="container-right sm:pt-24 py-20 lg:py-0">
        <div className="grid grid-cols-5 gap-x-6 gap-y-4 sm:gap-y-16">
          <div className="col-span-5 sm:col-span-2">
            <Content className="parsecontent-sm opacity-30">{fields.description}</Content>
          </div>
          <div className="col-span-5 sm:col-span-3 py-8 lg:py-0">
            <Slider
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 15
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 5
                }
              }}
              autoplay={{
                delay: 3000
              }}
            >
              {fields.slides?.map((slide, index) => (
                <Slide key={index} className="!h-auto flex items-center justify-center">
                  <LosseLink
                    to={slide?.link?.url || '/'}
                    className="hover:opacity-80 transition-opacity"
                    target={slide?.link?.target || '_self'}
                  >
                    <LossePlaatjie src={slide?.image} />
                  </LosseLink>
                </Slide>
              ))}
            </Slider>
          </div>
          {fields.showcta && fields.cta && (
            <div className="relative col-span-5 sm:col-start-3 sm:col-end-6 bg-metric-electrike p-4 sm:p-8 sm:mt-8 ">
              <Pentagon className="absolute top-0 sm:-left-24 h-full" />
              <div className="absolute top-0 left-full bg-metric-electrike h-full sm:w-[2000px]" />
              <Content className="relative parsecontent-xl text-white children-a:text-black">{fields.cta}</Content>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
