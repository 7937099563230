import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import HeroCube from './HeroCube'
import HeroDefault from './HeroDefault'
import HeroTeammember from './HeroTeammember'

export default function HeroShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: HeroDefault,
    cube: HeroCube,
    teammember: HeroTeammember
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
