import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Form from '~/components/elements/Form'
import type { Component_Newsletter } from '~/graphql/types'
import { LosseContent, useLoaderData, useLocation, useLosseData } from '@ubo/losse-sjedel'

// Components
import Loading from '~/components/elements/Loading'
import useMediaQuery from '~/helpers/useMediaQuery'

const useOutsideClick = (callback: any) => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClick = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref])

  return ref
}

export default function NewsLetter() {
  const { hasContentstable } = useLoaderData()

  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width: 639px')

  const [response, state] = useLosseData<{ newsletter: Component_Newsletter }>('Newsletter', {
    id: 1134
  })

  const handleClickOutside = () => {
    setOpen(false)
  }

  const ref = useOutsideClick(handleClickOutside)

  if(hasContentstable) return null

  const { newsletter } = response || {}

  if (state !== 'done') return null

  return (
    <motion.div
      initial={{ right: isMobile ? -300 : -400 }}
      animate={open ? { right: 0 } : { right: isMobile ? -300 : -400 }}
      className="fixed flex z-[1000] bottom-10 sm:top-1/2 sm:-translate-y-1/2"
      id="NewsLetter"
      ref={ref}
    >
      <div className="relative cursor-pointer hover:opacity-75" onClick={() => setOpen(!open)}>
        <div className="w-[59px] h-[179px relative">
          <div className="absolute bg-[#000] py-4 px-6 rounded-t-xl top-[50px] sm:top-[86px] -left-[1.8rem] sm:-left-[3.3rem] h-fit text-white -rotate-90 select-none text-xs sm:text-base">
            NIEUWSBRIEF
          </div>
        </div>
      </div>
      <div className="w-[300px] sm:w-[400px] bg-metric-hariyama text-white p-4 sm:p-8">
        <div className="pb-3 text-white">Schrijf je in voor onze maandelijkse nieuwsbrief!</div>
        <Form
          generate
          data={newsletter?.form}
          renderLoading={() => <Loading />}
          privacyUrl="/privacy"
          privacyUrlPlacement="privacy voorwaarden"
          renderStatus={({ status, content }) => (
            <div>
              <LosseContent>{content}</LosseContent>
            </div>
          )}
        />
      </div>
    </motion.div>
  )
}
