import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import ContentstableGrid from '~/components/elements/ContentstableGrid'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextNumbers({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id={fields.sectionid || 'TextNumbers'} className="relative">
      <div className={`container-right ${fields.bgcolor === 'hariyama' ? 'text-white' : 'text-black'}`}>
        <ContentstableGrid>
          <div
            className={`sm:ml-7 relative pr-4 sm:py-8 xl:pr-20 p-16  ${fields.bgcolor === 'hariyama' ? 'bg-metric-hariyama xl:pl-64' : 'bg-transparent xl:pl-24'
              }`}
          >
            {fields.bgcolor === 'transparent' && <div className="absolute h-full w-[2px] right-0 sm:left-0 bottom-0 bg-metric-qwilfish" />}
            <Content className={`relative ${fields.bgcolor === 'hariyama' ? 'check-light' : ''} parsecontent-sm children:mb-4`}>
              {fields?.description}
            </Content>
          </div>
          {fields?.items && fields?.items?.length > 0 && fields.items?.map((item, index) => (
            <div
              key={index}
              className={`sm:ml-7 relative pr-4 sm:py-8 xl:pr-20 p-16  ${fields.bgcolor === 'hariyama' ? 'bg-metric-hariyama xl:pl-64' : 'bg-transparent xl:pl-24'
                }`}
            >
              {fields.bgcolor === 'transparent' && (
                <div className="absolute h-full w-[2px] right-0 sm:left-0 bottom-0 bg-metric-qwilfish" />
              )}
              <div className="absolute top-[75px] sm:top-12 left-6 sm:left-0 -translate-x-1/2 -translate-y-1/2 w-fit h-fit">
                <svg
                  width="54"
                  height="62"
                  viewBox="0 0 54 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="max-sm:w-10 max-sm:h-10"
                >
                  <path d="M54 46.4998V15.5002L27.0003 0L0 15.5002V46.4998L27.0003 62L54 46.4998Z" fill="#0D7B6D" />
                </svg>
                <div className="absolute left-0 top-0 w-full h-full z-10 text-white font-bold flex items-center justify-center">
                  {index + 1}.
                </div>
              </div>
              <Content className={`relative ${fields.bgcolor === 'hariyama' ? 'check-light' : ''} parsecontent-sm children:mb-4`}>
                {item?.description}
              </Content>
            </div>
          ))}
          {fields.link?.title && (
            <div className="mt-7 xl:ml-28">
              <LosseLink className="btn btn-metric-electrike" to={fields.link?.url || '/'}>
                {fields.link?.title}
              </LosseLink>
            </div>
          )}
        </ContentstableGrid>
      </div>
    </section>
  )
}
