import Content from '~/components/elements/Content'
import Quote from '~/components/svg/Quote'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextQuote({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextQuote" className="relative bg-metric-zangoose">
      <div className="container-right py-20">
        <div className={`${fields.containerwidth === 'small' ? 'lg:ml-40 lg:mr-20' : 'lg:ml-7'}`}>
          <div className="flex items-start relative">
            <div>
              <Quote className="w-[30px] mr-8 xl:mr-0 xl:w-[50px]" />
            </div>
            <Content className="parsecontent-quote pr-4 xl:pr-20 xl:pl-14 xl:w-[70%]">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
