export default function Brand({ className = '' }: { className?: string }) {
  return (
    <svg width="558" height="650" viewBox="0 0 558 650" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        opacity="0.1"
        d="M279 0L0 162.5V487.5L279 650L558 487.5V162.5L279 0ZM454.752 434.778C427.27 434.778 404.958 412.309 404.958 384.543C404.958 384.062 404.958 383.58 404.958 383.059L331.618 351.762C320.84 375.355 297.216 391.725 269.773 391.725C256.688 391.725 244.478 387.994 234.098 381.534L194.127 431.568C202.002 440.997 206.774 453.194 206.774 466.515C206.774 496.528 182.672 520.843 152.923 520.843C123.174 520.843 99.0718 496.528 99.0718 466.515C99.0718 436.503 123.174 412.188 152.923 412.188C162.11 412.188 170.781 414.515 178.377 418.648L218.586 368.293C208.087 356.216 201.683 340.367 201.683 323.034C201.683 310.596 204.984 298.96 210.712 288.889L130.253 239.577C123.89 243.71 116.333 246.157 108.219 246.157C85.788 246.157 67.5725 227.821 67.5725 205.151C67.5725 182.481 85.7482 164.145 108.219 164.145C130.691 164.145 148.866 182.481 148.866 205.151C148.866 212.012 147.156 218.472 144.213 224.13L223.439 272.679C235.569 261.284 251.876 254.302 269.773 254.302C273.75 254.302 277.648 254.664 281.426 255.346L296.738 179.432C282.54 171.889 272.835 156.843 272.835 139.509C272.835 114.593 292.841 94.4105 317.539 94.4105C342.237 94.4105 362.243 114.593 362.243 139.509C362.243 164.426 342.237 184.608 317.539 184.608C317.181 184.608 316.823 184.608 316.425 184.608L300.835 261.926C309.306 266.299 316.704 272.398 322.63 279.781L380.975 229.506C379.384 225.093 378.43 220.358 378.43 215.383C378.43 192.753 396.606 174.377 419.077 174.377C441.548 174.377 459.724 192.713 459.724 215.383C459.724 238.052 441.548 256.389 419.077 256.389C408.975 256.389 399.708 252.617 392.589 246.478L333.01 297.796C336.073 305.62 337.783 314.127 337.783 323.034C337.783 326.043 337.584 329.012 337.186 331.901L409.691 362.836C417.724 345.944 434.826 334.269 454.593 334.269C482.075 334.269 504.387 356.738 504.387 384.503C504.387 412.269 482.115 434.738 454.593 434.738L454.752 434.778Z"
        fill="white"
      />
    </svg>
  )
}
