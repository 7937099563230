import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Crystal from '~/components/svg/Crystal'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function FormVacancy({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const teammember = fields.teammember![0]

  return (
    <section id="form-vacancy" className="relative bg-metric-zangoose form-custom margin-tight overflow-hidden">
      <div className="container-right grid grid-cols-3 pb-4">
        <div className="relative p-16 col-span-3 sm:col-span-1 self-center">
          <Crystal type="blue" className="absolute top-12 -left-28 w-full h-full opacity-10" />
          <Crystal type="blue" className="absolute -bottom-12 -left-[49%] w-1/2 h-1/2 opacity-10" />
          <div className="relative">
            <LossePlaatjie src={teammember?.recap?.highlightedPhoto} />
          </div>
          <div className="ml-7 text-metric-cinderace">
            <span className="text-lg block font-bold mb-2 mt-7">{teammember?.title}</span>
            <span className="block">{teammember?.recap?.jobtitle}</span>
          </div>
        </div>
        <div className="col-span-3 lg:col-span-2 p-4 sm:p-20">
          <Content className="parsecontent-h parsecontent-md mb-5">{fields?.description}</Content>
          <Form
            data={fields.form}
            generate={false}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => (
              <div>
                <Content>{content}</Content>
              </div>
            )}
            className="relative"
          >
            <>
              <div className="grid grid-cols-2 gap-x-7">
                <div className="col-span-2 sm:col-span-1">
                  <FormDuufGroup showIds={[3, 4]} />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <FormDuufGroup showIds={[5, 7]} />
                </div>
                <div className="col-span-2">
                  <FormDuufGroup showIds={[6]} />
                </div>
              </div>
              <FormDuufSubmit />
            </>
          </Form>
        </div>
      </div>
    </section>
  )
}
