/* eslint-disable react/no-array-index-key */
import React from 'react'
import { LosseLink, LossePlaatjie, useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import type { HeaderLoaderData } from './Header'

export default function HeaderTopmenu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { isSticky, isScrollable } = useLosseLayout()

  return (
    <ul className="relative flex justify-end items-center">
      {header?.topmenuHeader?.map((edge, index) => (
        // @ts-ignore
        <motion.li className={`${index === header.topmenuHeader?.length - 1 ? 'lg:mr-6 ml-6' : 'ml-6'}`} key={index}>
          <div className="flex flex-wrap items-center">
            <LosseLink
              className={`transition-all flex items-center text-white hover:underline text-sm ${
                isSticky || !isScrollable ? 'py-1' : 'py-4'
              }`}
              to={edge?.link?.url || '/'}
              target={edge?.link?.target || '_self'}
            >
              <LossePlaatjie src={edge?.icon} />
              <span className="ml-1">{edge?.link?.title}</span>
            </LosseLink>
          </div>
        </motion.li>
      ))}
    </ul>
  )
}
