import type { Download, Post, Teammember } from '~/graphql/types'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import type { ReactElement } from 'react'
import { useState } from 'react'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import { LosseBlogBink, LosseLink, LossePlaatjie, useLosseBlogBink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { AnimatePresence, motion } from 'framer-motion'
import ArrowDiamond from '~/components/svg/ArrowDiamond'
import ChevronDown from '~/components/svg/ChevronDown'
import Modal from '~/components/elements/Modal'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { useDetectClickOutside } from 'react-detect-click-outside'

export default function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  // @ts-ignore
  const __typename = fields.posts?.edges![0]?.node?.__typename

  let component: ReactElement
  switch (__typename?.toLowerCase()) {
    case 'teammember':
      component = <TeammemberOverview fields={fields} />
      break
    case 'download':
      component = <DownloadsOverview fields={fields} />
      break
    default:
      component = <PostsOverview fields={fields} />
      break
  }

  if (!fields.posts?.pageInfo) {
    return
  }

  return (
    <section id="Overview">
      <LosseBlogBink pageInfo={fields.posts?.pageInfo}>{component}</LosseBlogBink>
    </section>
  )
}

function TeammemberOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <div className="relative" id="team">
      <LossePlaatjie src={fields.background} className="absolute left-0 right-0 -bottom-32 w-full h-full" />
      <div className="relative pb-14">
        <Content className="parsecontent-extrabold parsecontent-lg text-metric-hariyama mb-9">{fields.description}</Content>
        <Slider
          mousewheel={{
            sensitivity: 100,
            forceToAxis: true
          }}
          direction="horizontal"
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 30
            },
            1600: {
              slidesPerView: 4.5,
              spaceBetween: 40
            }
          }}
        >
          {fields.posts?.edges?.map((edge, index) => {
            const post = edge?.node as Teammember
            return (
              <Slide key={index} className="relative !h-auto">
                <LossePlaatjie src={post.recap?.photo} className="lg:h-[534px] w-full object-cover" />
                <div className="text-white absolute bottom-0 w-full bg-gradient-to-t from-metric-cinderace via-transparent h-full p-8 flex flex-col justify-end">
                  <h3 className="font-bold text-lg mb-1 text-white">{post.title}</h3>
                  <span className="text-sm">{post.recap?.jobtitle}</span>
                </div>
              </Slide>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

function PostsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(-1)
  const blogBink = useLosseBlogBink()
  const parentFilters = fields.filters?.filter((filter) => !filter?.parentDatabaseId)

  const ref = useDetectClickOutside({ onTriggered: () => setCurrentFilter(-1) })

  return (
    <>
      {/* @ts-ignore */}
      {fields.posts?.edges?.length > 2 && (
        <div className="bg-metric-zangoose">
          <div className="container-right grid grid-cols-3 gap-x-6 py-12">
            {parentFilters?.map((parentFilter, parentIndex) => {
              const filters = fields.filters?.filter((filter) => filter?.parentDatabaseId === parentFilter?.databaseId)
              return (
                <div className="col-span-3 sm:col-span-1" key={parentFilter?.databaseId}>
                  <div className="relative">
                    <button
                      ref={ref}
                      type="button"
                      className={`text-base flex w-full items-center justify-between pl-6 pr-4 py-4 bg-white`}
                      onClick={(e) => {
                        e.preventDefault()
                        if (currentFilter === parentIndex) {
                          setCurrentFilter(-1)
                        } else {
                          setCurrentFilter(parentIndex)
                        }
                      }}
                    >
                      <span className="mr-2">{parentFilter?.name}</span>
                      <ChevronDown className={currentFilter === parentIndex ? 'rotate-180' : 'rotate-0'} />
                    </button>
                    <AnimatePresence>
                      {currentFilter === parentIndex && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="absolute z-[10] px-6 left-0 bg-white w-full h-fit-content py-6 shadow-lg"
                        >
                          {filters?.map((filter) => (
                            <div key={filter?.databaseId}>
                              <label className="flex items-center hover:opacity-80 hover:cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={blogBink.isFilterActive(filter?.databaseId)}
                                  onChange={() => {
                                    blogBink.toggleFilter(filter?.databaseId)
                                  }}
                                  className="appearance-none h-4 w-4 mr-3 block rounded-sm bg-metric-shiny-magneton checked:bg-metric-electrike checked:border-metric-electrike hover:bg-metric-qwilfish  focus:outline-metric-electrike transition duration-200"
                                />
                                <span>{filter?.name}</span>
                              </label>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      <div className="container-right pt-24">
        <div className="grid grid-cols-3 gap-6">
          {fields.posts?.edges?.map((edge, index) => {
            let isBigImage = false
            if (index % 0 || index % 6 === 0 || index % 11 === 0) {
              isBigImage = true
            }

            const post = edge?.node as Post
            const open = currentIndex === index

            if (post.title?.toLowerCase().includes('fast lane benelux')) {
              return null
            }

            if (post.title?.toLowerCase().includes('vibe group')) {
              return null
            }

            return (
              <motion.div
                onTap={() => setCurrentIndex(index)}
                onHoverStart={() => setCurrentIndex(index)}
                key={index}
                className={`relative col-span-3 group ${isBigImage ? 'sm:col-span-2' : 'sm:col-span-1'} overflow-hidden`}
              >
                <LosseLink to={post.uri || '/'}>

                  <div className="absolute left-6 top-6 bg-metric-hariyama">
                    <span className="block text-white px-2 py-1">
                      {/* @ts-ignore */}
                      {post.__typename === 'Vacancy' ? (
                        'Vacature'
                      ) : (
                        <>{post.categories?.edges?.map((category) => `${category?.node?.name}`)}</>
                      )}
                    </span>
                  </div>
                  <LossePlaatjie
                    src={post.recap?.thumbnail}
                    className="h-full lg:h-[534px] w-full object-cover aspect-square sm:aspect-[unset]"
                  />
                  <motion.div>
                    <LosseLink
                      className="lg:h-[115px] absolute bottom-0 bg-metric-qwilfish px-5 py-6 lg:py-8 lg:px-10 text-white w-full transition-all"
                      to={post.uri || '/'}
                    >
                      <div className="relative w-full h-full">
                        <div className="absolute -right-6 -top-20">
                          <ArrowDiamond />
                        </div>
                        <h3 className="font-bold first-line:md:text-lg group-hover:underline line-clamp-2">{post.title?.toUpperCase()}</h3>
                      </div>
                    </LosseLink>
                  </motion.div>
                </LosseLink>
              </motion.div>
            )
          })}
        </div>
      </div>
    </>
  )
}

function DownloadsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div className="container-right pt-24">
        <div className="grid grid-cols-3 gap-6">
          {fields.posts?.edges?.map((edge, index) => {
            const post = edge?.node as Download
            const open = currentIndex === index

            return (
              <>
                <motion.div
                  onTap={() => setCurrentIndex(index)}
                  onHoverStart={() => setCurrentIndex(index)}
                  onClick={() => setOpenModal(true)}
                  key={index}
                  className={`relative cursor-pointer col-span-3 group sm:col-span-1 overflow-hidden`}
                >
                  <div>
                    <AnimatePresence>
                      {!open && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 0.6 }}
                          exit={{ opacity: 0 }}
                          className="absolute top-0 w-full h-full mix-blend-multiply bg-metric-cinderace"
                        />
                      )}
                    </AnimatePresence>
                    <LossePlaatjie
                      src={post.recap?.thumbnail}
                      className="xl:h-[469px] w-full object-cover aspect-square sm:aspect-[unset]"
                    />
                    <motion.div>
                      <div
                        className={` md:h-[115px] absolute bottom-0 bg-metric-qwilfish px-3 py-4 md:px-5 md:py-6 lg:py-8 lg:px-10 text-white w-full transition-all ${open ? 'bottom-0' : 'bottom-0 lg:-bottom-[15%]'
                          }`}
                      >
                        <div className="relative w-full h-full">
                          <div className="absolute right-8 md:-right-6 -top-14 xl:-right-10 md:-top-20 w-[20px] h-[20px] md:w-auto md:h-auto">
                            <ArrowDiamond />
                          </div>
                          <h3 className="font-bold text-base md:text-lg group-hover:underline line-clamp-2">{post.title?.toUpperCase()}</h3>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </motion.div>
                <Modal isOpen={openModal} closeFunction={() => setOpenModal(false)}>
                  <span className="text-xl sm:text-3xl text-white block mb-16 font-bold font-sans">Download {`"${post.title}"`}</span>
                  <Form
                    data={fields.form}
                    defaultFields={{ input_6: post.title || 'Download' }}
                    generate={false}
                    renderLoading={() => <Loading />}
                    renderStatus={({ status, content }) => {
                      return (
                        <div className="text-white">
                          {status === 'done' && (
                            <div>
                              <span className="text-center">
                                Bedankt voor je interesse in <span className="font-bold">{`"${post.title}"`}.</span>
                              </span>
                              <br />
                              <a
                                href={post.recap?.file?.mediaItemUrl || ''}
                                target="_blank"
                                rel="noreferrer"
                                className="text-metric-electrike hover:underline text-center"
                              >
                                Klik hier
                              </a>
                              <span className="text-center"> voor je download.</span>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  >
                    <>
                      <div className="grid grid-cols-2 gap-x-7">
                        <div className="col-span-2 sm:col-span-1">
                          <FormDuufGroup showIds={[1, 3]} />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <FormDuufGroup showIds={[4, 5]} />
                        </div>
                      </div>
                      <FormDuufSubmit />
                    </>
                  </Form>
                </Modal>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}
