import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Crystal from '~/components/svg/Crystal'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerTitle({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData>()

  return (
    <section id="BannerTitle" className="relative bg-metric-hariyama text-white overflow-hidden margin-tight">
      {fields.image && (
        <>
          <LossePlaatjie src={fields.image} className="absolute inset-0 w-full h-full object-cover" />
          <div className="absolute top-0 w-full h-full mix-blend-multiply opacity-60 bg-metric-cinderace" />
        </>
      )}

      <Crystal type="white" className="absolute opacity-10 -bottom-20 left-72 w-[270px] h-[312px]" />
      <Crystal type="white" className="absolute -bottom-full -left-10 opacity-10 w-[200px] h-[210x] sm:w-auto sm:h-auto sm:-left-64" />
      <div className="pt-24 pb-14 lg:pt-64 lg:pb-28">
        <div className="container mb-6">
          <Breadcrumbs />
        </div>
        <div className="relative container-right">
          <h1 className="text-4xl lg:text-5xl uppercase font-extrabold">{page.title}</h1>
        </div>
      </div>
    </section>
  )
}
