import { memo } from 'react'

function Diamond({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="418" height="241" fill="none" viewBox="0 0 418 241" className={className}>
      <g clipPath="url(#clip0_512_832)">
        <path fill="#fff" d="M208.86 240.6l208.32-120.17L208.59 0 0 120.43 208.86 240.6z"></path>
      </g>
      <defs>
        <clipPath id="clip0_512_832">
          <path fill="#fff" d="M0 0H417.18V240.6H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default memo(Diamond)
