/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import { motion, AnimatePresence } from 'framer-motion'

export default function HeaderMobile({ totalPosts }: { totalPosts: number }) {
  const layout = useLosseLayout()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { pathname } = useLocation()
  const { isScrollable, setScrollable } = useLosseLayout()

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  }, [pathname])
  useEffect(() => {
    if (isOpen && isScrollable) {
      setScrollable(false)
    } else if (!isOpen && !isScrollable) {
      setScrollable(true)
    }
  }, [isOpen])

  return (
    <div className="relative block lg:hidden py-2 z-[100]">
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)

          layout.setScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        aria-label="Open menu"
      >
        <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-metric-cinderace bg-glass"
            initial={{ x: '100vh' }}
            animate={{ x: '0vh' }}
            exit={{ x: '100vh' }}
            transition={{
              bounce: 0
            }}
          >
            <div
              className="absolute right-5 top-12 text-center"
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)

                layout.setScrollable(true)
              }}
              aria-label="Close menu"
            >
              <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white" />
            </div>
            <ul className="pl-16 m-0 w-full">
              <HeaderMenu totalPosts={totalPosts} />
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
