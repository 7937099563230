import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import TextImage from './TextImage'
import TextVector from './TextVector'
import TextList from './TextList'
import TextQuote from './TextQuote'
import TextDownloads from './TextDownloads'
import TextAccordion from './TextAccordion'
import TextTypeform from './TextTypeform'
import TextNumbers from './TextNumbers'
import TextUsps from './TextUsps'

export default function TextShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: TextDefault,
    image: TextImage,
    vector: TextVector,
    list: TextList,
    quote: TextQuote,
    downloads: TextDownloads,
    accordion: TextAccordion,
    typeform: TextTypeform,
    numbers: TextNumbers,
    usps: TextUsps
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
