import { useEffect, useRef } from 'react'

export default function CookiebotDeclaration() {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return () => {}

    const script = document.createElement('script')
    script.setAttribute('id', 'CookieDeclaration')
    script.setAttribute('src', 'https://consent.cookiebot.com/8db2b6a8-3c3f-4835-b761-9acf563611d2/cd.js')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')
    ref.current?.append(script)

    return () => {}
  }, [])

  return <div ref={ref} />
}
