import type { Page_Flexcontent_Flex_Text } from "~/graphql/types";

export default function TextUsps({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id={fields.sectionid || 'TextUsps'} className="relative">
      <div className="container py-10 sm:py-14">
        <div className="relative md:px-20">
          <div className="grid lg:grid-cols-3 gap-8 lg:gap-20 xl:gap-32">
            {fields?.usps?.map((usp, index) => (
              <div key={index} className="sm:col-span-1 flex items-center gap-6">
                <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg" className="min-w-[36px]">
                  <path d="M36 31.4999V10.5001L18.0002 0L0 10.5001V31.4999L18.0002 42L36 31.4999Z" fill="#12478B" />
                </svg>
                <p className="text-lg font-bold mb-0">{usp?.usp}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
