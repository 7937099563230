import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Crystal from '~/components/svg/Crystal'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroTeammember({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const teammember = fields.teammember![0]
  return (
    <section className="relative bg-metric-zangoose margin-tight overflow-hidden">
      <div className="container-right grid grid-cols-3 py-12">
        <div className="relative p-16 col-span-3 sm:col-span-1 self-center">
          <Crystal type="blue" className="absolute top-12 -left-28 w-full h-full opacity-10" />
          <Crystal type="blue" className="absolute -bottom-12 -left-[49%] w-1/2 h-1/2 opacity-10" />
          <div className="relative">
            <LossePlaatjie src={teammember?.recap?.highlightedPhoto} />
          </div>
          <div className="ml-7 text-metric-cinderace">
            <span className="text-lg block font-bold mb-2 mt-7">{teammember?.title}</span>
            <span className="block">{teammember?.recap?.jobtitle}</span>
          </div>
        </div>
        <div className="col-span-3 xl:col-span-2 sm:p-20">
          <Content className="parsecontent-h parsecontent-md mb-5 children-p:lg:pr-44 children-p:mt-6">{fields?.description}</Content>

          <LosseLink className="btn btn-metric-electrike mt-10" to={fields.link?.url}>
            {fields.link?.title}
          </LosseLink>
        </div>
      </div>
    </section>
  )
}
