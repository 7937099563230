import type { Page_Flexcontent_Flex_Posts, Post } from '~/graphql/types'
import type { ReactElement } from 'react'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie, useLocation } from '@ubo/losse-sjedel'
import Crystal from '~/components/svg/Crystal'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import { motion } from 'framer-motion'
import ArrowDiamond from '~/components/svg/ArrowDiamond'
import useMedia from 'use-media'

export default function Highlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const __length = fields.highlighted?.length

  let component: ReactElement
  switch (__length) {
    case 1:
      component = <Case fields={fields} />
      break
    default:
      component = <Posts fields={fields} />
      break
  }

  return <section id="Highlighted">{component}</section>
}

function Case({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { pathname } = useLocation()

  return (
    <div className="container pt-10 lg:py-20">
      {fields.highlighted?.map((edge, index) => (
        <div className="grid grid-cols-5" key={index}>
          <div className="relative col-span-5 sm:col-span-2 mx-auto md:mx-0 mb-4 md:mb-0">
            <Crystal
              type="blue"
              className={`absolute top-0 left-12 sm:-left-24 w-[250px] md:w-full h-auto ${pathname === '/' ? 'opacity-100' : 'opacity-20'}`}
            />
            <LossePlaatjie
              src={edge?.recap?.highlightedImage}
              className="relative z-10 -ml-10 lg:-ml-12 w-full h-auto max-w-[250px] sm:max-w-none"
            />
          </div>
          <div className="col-span-5 sm:col-span-3 lg:mt-16">
            <Content className="parsecontent-3xl text-metric-hariyama sm:-ml-16">{fields.description}</Content>
            <div key={index} className="relative bg-metric-hariyama py-16 pr-4 sm:pr-16 lg:-mt-7">
              <div className="absolute top-0 -left-24 w-24 h-full bg-metric-hariyama" />
              <Content className="text-white parsecontent-lg">{edge?.recap?.excerpt}</Content>
              {/* <div className="mt-4 lg:mt-0">
                <LosseLink className="text-metric-electrike font-bold hover:underline" to={edge?.uri || ''}>
                  {`> ${fields.readmoretext}`}
                </LosseLink>
              </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function Posts({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className="relative">
      <LossePlaatjie src={fields.background} className="absolute left-0 right-0 -bottom-32 w-full h-full" />
      <div className="relative pt-28 pb-14">
        <Content className="parsecontent-lg text-metric-hariyama mb-9">{fields.description}</Content>
        <Slider
          mousewheel={{
            sensitivity: 100,
            forceToAxis: true
          }}
          direction="horizontal"
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 30
            },
            1600: {
              slidesPerView: 4.5,
              spaceBetween: 40
            }
          }}
        >
          {fields.highlighted?.map((edge, index) => {
            const post = edge as Post
            const open = currentIndex === index
            return (
              <Slide key={index} className="relative !h-auto">
                <LosseLink className="relative w-full h-full group" to={post.uri || '/'}>
                  <motion.div onHoverStart={() => setCurrentIndex(index)} onHoverEnd={() => setCurrentIndex(0)}>
                    <LossePlaatjie src={post.recap?.thumbnail} className="lg:h-[534px] w-full object-cover" />
                    <motion.div
                      className="absolute bottom-0 bg-metric-qwilfish bg-opacity-70 sm:bg-opacity-100 py-8 px-10 text-white w-full"
                      initial={{ y: '80%' }}
                      animate={open ? { y: '0%' } : { y: '90%' }}
                      transition={{
                        bounce: 0
                      }}
                    >
                      <div className="relative">
                        <div className="absolute -right-6 -top-20">
                          <ArrowDiamond />
                        </div>
                        <span className="block font-bold text-sm sm:text-lg">
                          {post.categories?.edges?.map((category) => `${category?.node?.name?.toUpperCase()}:`)}
                        </span>
                        <h3 className="font-bold text-lg group-hover:underline">{post.title?.toUpperCase()}</h3>
                      </div>
                    </motion.div>
                  </motion.div>
                </LosseLink>
              </Slide>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
