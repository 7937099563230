/* eslint-disable react-hooks/exhaustive-deps */
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import type { Page_Flexcontent_Flex_Contentstable } from '~/graphql/types'
import useMediaQuery from '~/helpers/useMediaQuery'

export default function Contentstable({ fields }: { fields: Page_Flexcontent_Flex_Contentstable }) {
  const [activeItem, setActiveItem] = useState(-1)
  const elementRef = useRef<HTMLDivElement>(null)
  const is2000 = useMediaQuery('(min-width: 2000px)')
  const is1586 = useMediaQuery('(min-width: 1586px)')
  const is1536 = useMediaQuery('(min-width: 1536px)')
  const is1440 = useMediaQuery('(min-width: 1440px)')
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  let offset = 600

  if (is2000) {
    offset = 561
  } else if (is1586) {
    offset = 587
  } else if (is1536) {
    offset = 614
  } else if (is1440) {
    offset = 608
  } else if (isDesktop) {
    offset = 659
  }

  const handleScroll = () => {
    if (!elementRef.current || !isDesktop) return

    const elements = document.getElementsByClassName('contentstable')
    const scrollPos = window.scrollY

    let _activeIndex = -1

    Array.from(elements).forEach((el, index) => {
      const element = el as HTMLElement
      const top = element.getBoundingClientRect().top + document.documentElement.scrollTop

      if (scrollPos + 250 >= top) _activeIndex = index
    })

    if (_activeIndex !== activeItem) setActiveItem(_activeIndex)

    const startSection = document.getElementById(fields?.startSection || '')
    const endSection = document.getElementById(fields?.endSection || '')

    if (startSection && endSection) {
      const startSectionTop = startSection.getBoundingClientRect().top + document.documentElement.scrollTop - offset
      const endSectionTop = endSection.getBoundingClientRect().top + document.documentElement.scrollTop + endSection.clientHeight - 500
      const _scrollPos = scrollPos + 125

      if (_scrollPos >= startSectionTop && _scrollPos <= endSectionTop) {
        elementRef.current.style.top = `${_scrollPos - startSectionTop - offset}px`
      }
    }
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [elementRef, isDesktop])

  return (
    <section id="Contentstable" className="relative">
      <div ref={elementRef} className={clsx('w-full z-50 lg:absolute')} style={{ top: -offset }}>
        <div className="container">
          <div className="lg:grid grid-cols-12 gap-10 xl:gap-20">
            <div className="col-span-8 xl:col-span-9 max-lg:hidden" />
            <div className="col-span-4 xl:col-span-3 relative">
              <aside className="bg-metric-qwilfish p-6 xl:p-10 text-white lg:absolute">
                <h2 className="uppercase font-black text-lg">{fields.title}</h2>
                <div className="mt-4 text-sm">
                  {fields?.items?.map((item, index) => (
                    <LosseLink
                      key={index}
                      className={clsx(
                        'flex items-start py-2 xl:py-4',
                        index !== (fields.items?.length || 0) - 1 && 'border-b border-b-white/75',
                        activeItem === index && 'font-black'
                      )}
                      to={item?.link?.url}
                      target={item?.link?.target || undefined}
                    >
                      <div className="min-w-[35px] max-w-[35px]">{index + 1}.</div>
                      <div className="flex-grow">{item?.link?.title}</div>
                    </LosseLink>
                  ))}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
