import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import Highlighted from './Highlighted'
import Latest from './Latest'
import Overview from './Overview'

const PostsShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    blog: Overview,
    highlighted: Highlighted,
    latest: Latest
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default PostsShell
