import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextImage" className="relative">
      <div className="container-right grid grid-cols-2">
        <div
          className={`col-span-2 mt-4 mb-4 lg:mb-0 sm:col-span-1 lg:-ml-12 sm:mt-16 ${
            fields.imageposition === 'right' ? 'order-last' : ''
          }`}
        >
          <LossePlaatjie src={fields.image} className="w-full h-auto lg:h-full max-w-[300px] mx-auto sm:max-w-none" />
        </div>
        <div
          className={`col-span-2 relative sm:col-span-1 self-center ml-6 sm:ml-10 ${fields.imageposition === 'right' ? 'order-first' : ''}`}
        >
          <div className="absolute h-[100%] w-1 left-0 bottom-0 bg-metric-qwilfish" />
          <div className="pl-10 sm:py-28">
            <Content className="parsecontent-lg parsecontent-h">{fields?.description}</Content>
            {fields.link?.url && (
              <LosseLink className="btn btn-metric-electrike mt-8" to={fields.link.url}>
                {fields.link.title}
              </LosseLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
