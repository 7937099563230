import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

import Content from '~/components/elements/Content'

import type { Page_Flexcontent_Flex_Grid, Teammember } from '~/graphql/types'

export default function GridTeammembers({ fields }: { fields: Page_Flexcontent_Flex_Grid }) {
  // filter the fields.teammebers and group them per 2 and 3 items
  const teammembers = fields.teammembers?.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push([item])
    } else {
      acc[acc.length - 1].push(item)
    }
    return acc
  }, [] as Teammember[][])

  return (
    <section id="team" className="relative lg:py-20">
      <div className="container-right grid xl:grid-cols-2 gap-x-12">
        <div className={`col-span-2 relative lg:col-span-1 self-center ml-6 xl:ml-10`}>
          <div className="absolute h-[100%] w-1 left-0 bottom-0 bg-metric-qwilfish" />
          <div className="pl-10 sm:py-20 md:pr-20">
            <Content className="parsecontent-lg parsecontent-h">{fields?.description}</Content>
          </div>
        </div>
        <div className={`relative flex flex-wrap justify-around lg:-top-4 col-span-2 lg:col-span-1 lg:ml-8 mt-16 `}>
          <div>
            {teammembers.map((row: any, rowIndex: number) => (
              <div
                key={rowIndex}
                className={`row-${rowIndex} flex items-center gap-2 mb-4 lg:mb-0 ${
                  rowIndex % 2 === 1 ? 'ml-12 -mt-[2.25rem] md:-mt-14 lg:-mt-10 md:ml-20' : '-mt-[2.25rem] md:-mt-[3.45rem] lg:-mt-[2.4rem]'
                }`}
              >
                {row.map((teammember: object, index: number) => {
                  const member = teammember as Teammember
                  return (
                    <div key={index} className="group">
                      <div className="relative flex justify-center">
                        <LossePlaatjie
                          src={member?.recap?.highlightedPhoto}
                          className="group-hover:scale-105 transition-transform w-[90px] h-auto sm:w-[155px] sm:h-[180px] object-cover"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
