export default function LogoAlt({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="167" height="41" fill="none" viewBox="0 0 167 41" className={className}>
      <path
        fill="#5ACD8F"
        d="M17.6 0L0 10.25v20.498l17.6 10.25 17.598-10.25V10.25L17.6 0zm11.085 27.423c-1.733 0-3.14-1.417-3.14-3.169v-.093l-4.627-1.974a4.291 4.291 0 01-3.9 2.52 4.244 4.244 0 01-2.251-.643l-2.522 3.156c.497.595.798 1.364.798 2.204 0 1.893-1.52 3.427-3.397 3.427-1.876 0-3.397-1.534-3.397-3.427 0-1.892 1.52-3.426 3.397-3.426.58 0 1.127.147 1.606.407l2.536-3.176a4.34 4.34 0 01-1.066-2.854c0-.785.208-1.519.57-2.154l-5.076-3.11c-.401.26-.878.415-1.39.415a2.575 2.575 0 01-2.564-2.586 2.573 2.573 0 012.564-2.587A2.573 2.573 0 019.39 12.94c0 .432-.107.84-.293 1.197l4.997 3.062a4.257 4.257 0 013.658-1.094l.966-4.788A2.847 2.847 0 0117.21 8.8c0-1.571 1.262-2.844 2.82-2.844 1.558 0 2.82 1.273 2.82 2.844 0 1.572-1.262 2.845-2.82 2.845h-.07l-.983 4.876c.534.276 1 .661 1.374 1.127l3.68-3.171c-.1-.279-.16-.577-.16-.891 0-1.428 1.147-2.587 2.564-2.587A2.573 2.573 0 0129 13.585a2.573 2.573 0 01-2.564 2.586 2.555 2.555 0 01-1.67-.625l-3.759 3.237a4.357 4.357 0 01.264 2.151l4.573 1.951a3.14 3.14 0 012.832-1.802c1.734 0 3.142 1.418 3.142 3.169 0 1.751-1.405 3.168-3.142 3.168l.01.003z"
      ></path>
      <path
        fill="#fff"
        d="M56.809 29l-.048-8.797-4.197 7.162h-2.052l-4.175-6.925v8.562H42.07V12.4h3.8l5.736 9.582 5.597-9.582h3.8l.048 16.6h-4.245.003zM77.419 25.373v3.629H64.315V12.4h12.8v3.63h-8.231v2.798h7.25v3.51h-7.25v3.035h8.535zM83.457 16.123h-5.012V12.4h14.619v3.723h-4.99V29h-4.617V16.123zM101.925 24.59h-2.52v4.412H94.79V12.4h7.461c4.453 0 7.25 2.349 7.25 6.142 0 2.443-1.167 4.244-3.194 5.241l3.52 5.216h-4.942l-2.96-4.41v.002zm.047-8.487h-2.564v4.861h2.564c1.912 0 2.868-.9 2.868-2.42 0-1.518-.956-2.441-2.868-2.441zM112.045 12.4h4.616V29h-4.616V12.4zM119.107 20.701c0-5.051 3.801-8.632 8.977-8.632 3.008 0 5.431 1.113 7.017 3.13l-2.938 2.703c-1.026-1.258-2.286-1.944-3.849-1.944-2.681 0-4.545 1.899-4.545 4.743 0 2.845 1.866 4.743 4.545 4.743 1.563 0 2.823-.689 3.849-1.944l2.938 2.703c-1.586 2.014-4.012 3.13-7.017 3.13-5.176 0-8.977-3.58-8.977-8.632zM136.456 20.511h6.808v3.462h-6.808v-3.462zM146.014 12.4h4.616V29h-4.616V12.4zM157.394 16.123h-5.013V12.4H167v3.723h-4.99V29h-4.616V16.123z"
      ></path>
    </svg>
  )
}
