import type { ReactNode } from 'react'
import ReactModal from 'react-modal'

export default function Modal({ isOpen, children, closeFunction }: { isOpen: boolean; children: ReactNode; closeFunction: any }) {
  const customStyles = {
    content: {
      padding: '3rem',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#001F4D'
    }
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={closeFunction} style={customStyles}>
      {children}
    </ReactModal>
  )
}
