import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Content from '~/components/elements/Content'
import { motion } from 'framer-motion'
import Crystal from '~/components/svg/Crystal'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerLanding" className="relative">
      <motion.div
        className="absolute left-0 h-full w-[120vw]"
        initial={{ x: '20vw' }}
        animate={{ x: '0vw' }}
        transition={{
          duration: 5
        }}
      >
        <LossePlaatjie loading="eager" className="!absolute top-0 left-0 h-full w-full" src={fields.background} maxwidth={1999} />
      </motion.div>
      <div className="absolute left-0 top-0 h-full w-2/3 bg-gradient-to-r from-black via-black" />
      <div className="container-right relative grid grid-cols-2">
        <div className="col-span-2 sm:col-span-1 self-center xl:self-end sm:order-last pt-32 sm:pt-0">
          <div className="relative">
            <Crystal type="white" className="absolute -top-1 w-full h-full left-6 sm:left-12" />
            <LossePlaatjie src={fields.image} className="sm:ml-28 relative w-full mx-auto max-w-[300px] sm:max-w-none" />
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1 pt-12 sm:pt-80 pb-12 sm:pb-52 sm:order-first">
          <Content className="parsecontent-xl text-white">{fields?.description}</Content>
          <div className="mt-8">
            {fields.landingCta?.title && (
              <>
                <LosseLink className="btn btn-metric-electrike mr-5 mb-4" to={fields.landingCta?.url || '/'}>
                  {fields.landingCta?.title}
                </LosseLink>
                <LosseLink className="btn btn-white mr-4" to={fields.link?.url || '/'}>
                  {fields.link?.title}
                </LosseLink>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
