import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

import Content from '~/components/elements/Content'

import type { Page_Flexcontent_Flex_Grid } from '~/graphql/types'

export default function GridCrystals({ fields }: { fields: Page_Flexcontent_Flex_Grid }) {
  return (
    <section id="GridCrystals" className="relative lg:py-44">
      <div className="container-right grid grid-cols-2">
        <div className={`col-span-2 relative lg:col-span-1 self-center ml-6 xl:ml-10`}>
          <div className="absolute h-[100%] w-1 left-0 bottom-0 bg-metric-qwilfish" />
          <div className="pl-10 sm:py-20">
            <Content className="parsecontent-lg parsecontent-h py-12 lg:py-0">{fields?.description}</Content>
            {fields.link?.url && (
              <LosseLink className="btn btn-metric-electrike mt-8" to={fields.link.url}>
                {fields.link.title}
              </LosseLink>
            )}
          </div>
        </div>
        <div className={`relative flex flex-wrap justify-around lg:-top-20 col-span-2 mt-4 mb-4 lg:mb-0 lg:col-span-1 xl:-ml-12 sm:mt-16 `}>
          {fields.crystals?.map((edge, index) => {
            let topClass: string = '-top-full'
            let leftClass: string = '-left-full'
            switch (index) {
              case 0:
                topClass = 'lg:-top-20 xl:-top-24'
                leftClass = 'lg:left-48 xl:left-56'
                break
              case 1:
                topClass = 'lg:top-[6rem] xl:top-[7.5rem]'
                leftClass = 'lg:left-[5.7rem] xl:left-[5.9rem]'
                break
              case 2:
                topClass = 'lg:top-[5.8rem] xl:top-[7.4rem]'
                leftClass = 'lg:left-[18.5rem] xl:left-[22rem]'
                break
              case 3:
                topClass = 'lg:top-[16.8rem] xl:top-[21rem]'
                leftClass = 'lg:-left-[0.7rem] xl:-left-[2.2rem]'
                break
              case 4:
                topClass = 'lg:top-[16.8rem] xl:top-[21rem]'
                leftClass = 'lg:left-[12.25rem] xl:left-[14rem]'
                break
            }

            return (
              <LosseLink to={edge?.link?.url} key={index} className={`group lg:absolute m-2 lg:m-0 ${topClass} ${leftClass}`}>
                <div className="relative flex justify-center">
                  <LossePlaatjie
                    src={edge?.image}
                    className="group-hover:scale-105 transition-transform w-[150px] h-[175px] md:w-[175px] md:h-[200px] lg:w-[200px] lg:h-[225px] xl:w-[250px] xl:h-[275px]"
                  />
                  <div className="absolute bottom-16 font-bold text-sm sm:text-base lg:text-lg xl:text-xl text-white text-center text-shadow">
                    {edge?.link?.title}
                  </div>
                </div>
              </LosseLink>
            )
          })}
        </div>
      </div>
    </section>
  )
}
