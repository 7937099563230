import { useState } from 'react'
import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Dropdown } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Arrow from '~/components/svg/Arrow'
import Content from '~/components/elements/Content'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import { Mousewheel } from 'swiper'

interface HeaderSubmenuProps {
  fields: Maybe<Component_Header_MenuHeader_Dropdown>[]
  closeSubmenu: (e: Event) => void
}

export default function HeaderSubmenu({ fields, closeSubmenu }: HeaderSubmenuProps) {
  const [currentEdge, setCurrentEdge] = useState<Maybe<Component_Header_MenuHeader_Dropdown>>(fields[0])
  const submenuRef = useDetectClickOutside({ onTriggered: closeSubmenu })

  // @ts-ignore
  const slider = currentEdge?.submenu?.length > 6

  return (
    <motion.div
      className="absolute z-[100] w-full left-0 bg-white shadow-md"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      ref={submenuRef}
    >
      <ul className="hidden container-right lg:grid lg:grid-cols-6 lg:gap-x-12 py-6">
        <li className="col-span-3 lg:col-span-1">
          {fields?.map((edge, index) => {
            const open = currentEdge === edge
            return (
              <motion.button
                className={`transition-all ${
                  open ? 'btn-metric-electrike' : ''
                } btn w-full flex justify-between items-center font-bold mb-12`}
                onHoverStart={() => setCurrentEdge(edge)}
                onClick={() => setCurrentEdge(edge)}
                key={index}
              >
                <span>{edge?.title}</span>
                <Arrow />
              </motion.button>
            )
          })}
        </li>
        <li className="col-span-5">
          {slider ? (
            <Slider slidesPerView={3.2} modules={[Mousewheel]} mousewheel>
              {currentEdge?.submenu?.map((edge, subIndex) => (
                <Slide key={subIndex} className="!h-auto">
                  <LosseLink
                    to={edge?.link?.url || '/'}
                    className="group flex h-full flex-col items-center border border-transparent hover:border-metric-electrike rounded-xl p-7"
                  >
                    <LossePlaatjie src={edge?.icon} maxwidth={56} className="w-[49px] h-[56px]" />
                    <Content className="parsecontent-sm mt-3">{edge?.description}</Content>
                    <span className="text-metric-electrike font-bold mt-4 group-hover:underline">{`> ${edge?.link?.title}`}</span>
                  </LosseLink>
                </Slide>
              ))}
            </Slider>
          ) : (
            <motion.ul
              className="grid grid-cols-3 gap-x-20"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'fit-content', overflow: 'hidden' }}
              exit={{ opacity: 0, height: 0 }}
            >
              {currentEdge?.submenu?.map((edge, subIndex) => (
                <li className="col-span-1" key={subIndex}>
                  <LosseLink
                    to={edge?.link?.url || '/'}
                    className="group flex h-full flex-col items-center border border-transparent hover:border-metric-electrike rounded-xl p-7"
                  >
                    <LossePlaatjie src={edge?.icon} maxwidth={56} className="w-[49px] h-[56px]" />
                    <Content className="parsecontent-sm mt-3">{edge?.description}</Content>
                    <span className="text-metric-electrike font-bold mt-4 group-hover:underline">{`> ${edge?.link?.title}`}</span>
                  </LosseLink>
                </li>
              ))}
            </motion.ul>
          )}
        </li>
      </ul>

      <div className="py-4 px-6 lg:hidden">
        {fields?.map((edge, index) => {
          const open = currentEdge === edge
          return (
            <motion.button
              className={`transition-all ${open ? 'btn-metric-electrike' : ''} btn w-full flex justify-between items-center font-bold mb-4`}
              onHoverStart={() => setCurrentEdge(edge)}
              onClick={() => setCurrentEdge(edge)}
              key={index}
            >
              <span>{edge?.title}</span>
              <Arrow className="rotate-90" />
            </motion.button>
          )
        })}

        <div>
          <Slider slidesPerView={1.8} spaceBetween={20}>
            {currentEdge?.submenu?.map((edge, subIndex) => (
              <Slide key={subIndex} className="!h-auto">
                <LosseLink to={edge?.link?.url || '/'} className="flex flex-col items-center">
                  <LossePlaatjie src={edge?.icon} maxwidth={56} className="w-[49px] h-[56px]" />
                  <Content className="parsecontent-sm mt-3">{edge?.description}</Content>
                  <span className="text-metric-electrike font-bold mt-4 hover:underline">{`> ${edge?.link?.title}`}</span>
                </LosseLink>
              </Slide>
            ))}
          </Slider>
        </div>
      </div>
    </motion.div>
  )
}
