import { useState } from 'react'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import Crystal from '~/components/svg/Crystal'
import type { Page_Flexcontent_Flex_Grid } from '~/graphql/types'

export default function GridDefault({ fields }: { fields: Page_Flexcontent_Flex_Grid }) {
  const [currentCol, setCurrentCol] = useState('')

  return (
    <section id="GridDefault" className="relative bg-metric-zangoose">
      <div className="pl-4 xl:container-right py-16">
        {fields.row?.map((edge, rowIndex) => {
          const odd = rowIndex % 2 === 1

          return (
            <div key={rowIndex} className="relative mb-16">
              <Crystal
                className={`absolute ${odd
                    ? '-left-16 sm:-right-[28%] sm:left-[unset] -bottom-1/4  h-[105px] w-[105px] sm:h-[390px] sm:w-[390px]'
                    : 'left-1 sm:-left-[40%] h-[200px] sm:h-[620px]'
                  }`}
              />
              <h2
                className={`relative text-metric-hariyama text-2xl sm:text-6xl font-extrabold mb-4 sm:mb-8 ${odd ? 'sm:text-right' : 'text-left'
                  }`}
              >
                {edge?.title}
              </h2>
              <div className={`${odd ? 'sm:ml-24 xl:mr-24 xl:ml-0' : 'sm:ml-24'}`}>
                <Slider
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                      spaceBetween: 15
                    },
                    768: {
                      slidesPerView: 2.2,
                      spaceBetween: 17
                    },
                    1024: {
                      slidesPerView: 2.8,
                      spaceBetween: 19
                    },
                    1280: {
                      slidesPerView: 3,
                      spaceBetween: 20
                    }
                  }}
                >
                  {edge?.elements?.map((edge, elementIndex) => {
                    const active = currentCol === `${rowIndex}-${elementIndex}`
                    return (
                      <Slide className="relative !aspect-square" key={elementIndex}>
                        <motion.div
                          onHoverStart={() => setCurrentCol(`${rowIndex}-${elementIndex}`)}
                          onClick={() => setCurrentCol(`${rowIndex}-${elementIndex}`)}
                          className="relative flex items-center justify-center top-0 left-0 w-full h-full bg-white px-4"
                        >
                          <div className="flex justify-center items-center">
                            <div className="flex flex-col items-center">
                              <LossePlaatjie
                                src={edge?.icon}
                                maxwidth={153}
                                className="w-[80px] h-[90px] sm:w-[132px] sm:h-[153px] sm:my-10"
                              />
                              <span className="text-lg sm:text-2xl text-center text-metric-hariyama font-bold">{edge?.link?.title}</span>
                            </div>
                          </div>
                        </motion.div>
                        <AnimatePresence>
                          {active && (
                            <motion.div
                              className="top-0 left-0 absolute w-full h-full"
                              initial={{ y: '100%' }}
                              animate={{ y: '0%' }}
                              exit={{ y: '100%' }}
                              transition={{ bounce: 0 }}
                            >
                              <div className="relative w-full h-full">
                                <div className="absolute z-10 top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent" />
                                <LosseLink
                                  to={edge?.link?.url || ''}
                                  className="relative z-20 w-full h-full flex flex-col justify-center items-center"
                                >
                                  <div>
                                    <LossePlaatjie
                                      src={fields.image}
                                      className="absolute top-0 left-0 bottom-0 w-full h-full aspect-square"
                                    />
                                    <div className="relative text-white flex flex-col h-auto justfiy-center items-center">
                                      <span className="text-base lg:text-2xl text-center font-bold lg:mb-12 drop-shadow-lg shadow-black">
                                        {edge?.link?.title}
                                      </span>
                                      <Content className="parsecontent-lg drop-shadow-lg shadow-black px-1 lg:px-6">
                                        {edge?.description}
                                      </Content>
                                    </div>
                                  </div>
                                </LosseLink>
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Slide>
                    )
                  })}
                </Slider>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
