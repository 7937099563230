import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Crystal from '~/components/svg/Crystal'
import Diamond from '~/components/svg/Diamond'
import DiamondLeft from '~/components/svg/DiamondLeft'
import DiamondRight from '~/components/svg/DiamondRight'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import clsx from 'clsx'

export default function HeroCube({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const { page } = useLoaderData<LoaderData>()
  const { ref, inView } = useInView()

  const cubeTransition = {
    duration: 1,
    bounce: 0
  }

  return (
    <section id="HeroCube" className="relative overflow-hidden">
      <LossePlaatjie src={fields.background} className="absolute h-full w-full" />

      <div className="grid relative container py-6 sm:py-20 sm:mt-40 mb-10 grid-cols-2 w-full sm:gap-x-4 sm:gap-y-4" ref={ref}>
        <motion.div initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 0 }} transition={{ delay: 1.2 }}>
          <Crystal
            className={clsx(
              page.title === 'Over ons' ? 'top-12 sm:-top-12' : 'top-2 sm:top-4 lg:-top-20',
              'absolute  left-0 right-0 mx-auto w-full md:w-auto h-full'
            )}
          />
        </motion.div>
        <motion.div
          className="relative top-[42%] sm:top-[unset] col-span-2 justfiy-self-center"
          initial={{ y: '-100vh' }}
          animate={inView ? { y: '0vh' } : { y: '-100vh' }}
          transition={cubeTransition}
        >
          <div className="group flex justify-center">
            <div className="relative">
              <Diamond className="transition-transform w-full h-full" />
              <span className="absolute top-0 left-0 right-0 bottom-0 flex z-10 w-full h-full justify-center items-center text-center text-base sm:text-2xl font-extrabold">
                {fields.cubes![0]?.link?.title}
              </span>
            </div>
          </div>
        </motion.div>
        <motion.div
          className="relative col-span-1 justify-self-end top-0 sm:-top-28"
          initial={{ x: '-100vw' }}
          animate={inView ? { x: '-0.5rem' } : { x: '-100vw' }}
          transition={cubeTransition}
        >
          <div className="group relative flex justify-center">
            <div className="relative">
              <DiamondLeft className="transition-transform w-full h-full" />
              <span className="absolute top-0 left-0 right-0 bottom-0 flex z-10 w-full h-full justify-center items-center text-center text-base sm:text-2xl font-extrabold">
                {fields.cubes![1]?.link?.title}
              </span>
            </div>
          </div>
        </motion.div>
        <motion.div
          className="relative col-span-1 justify-self-start top-0 sm:-top-28"
          initial={{ x: '100vw' }}
          animate={inView ? { x: '0.5rem' } : { x: '100vw' }}
          transition={cubeTransition}
        >
          <div className="group relative flex justify-center">
            <div className="relative">
              <DiamondRight className="transition-transform w-full h-full" />
              <span className="absolute top-0 left-0 right-0 bottom-0 flex z-10 w-full h-full justify-center items-center text-center text-base sm:text-2xl font-extrabold">
                {fields.cubes![2]?.link?.title}
              </span>
            </div>
          </div>
        </motion.div>
        {page?.title !== 'Over ons' && (
          <div className="relative col-span-2 justify-self-center mt-5 sm:mt-0">
            <LosseLink to={fields.link?.url || '/'} className="btn btn-metric-electrike">
              {fields.link?.title}
            </LosseLink>
          </div>
        )}
      </div>
    </section>
  )
}
