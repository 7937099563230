import { LossePlaatjie } from '@ubo/losse-sjedel'
import Crystal from '~/components/svg/Crystal'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerContact({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerContact" className="relative lg:h-[90vh] max-h-[750px] margin-tight">
      <div className="relative overflow-hidden h-full w-full">
        <div className="absolute right-0 bottom-36">
          <Crystal type="white" className="w-[264px] h-[308px]" />
        </div>
        <div className="absolute right-6 -bottom-80">
          <Crystal type="green" className="" />
        </div>
      </div>
      <LossePlaatjie
        loading="eager"
        className="relative lg:absolute top-0 left-0 -z-10 h-full w-full object-cover "
        src={fields.background}
      />
    </section>
  )
}
