export default function Quote({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="38" fill="none" viewBox="0 0 50 38" className={className}>
      <path
        fill="#12478B"
        d="M17.245 16.606L22.04 0H9.898l-6.53 14.976C.407 21.496 0 23.737 0 26.59 0 33.517 4.898 38 11.633 38c6.632 0 11.632-4.483 11.632-11.41 0-4.788-2.347-8.252-6.02-9.984zm26.735 0L48.776 0H36.633l-6.531 14.976c-2.96 6.52-3.367 8.761-3.367 11.614 0 6.927 4.898 11.41 11.632 11.41C45 38 50 33.517 50 26.59c0-4.788-2.347-8.252-6.02-9.984z"
      ></path>
    </svg>
  )
}
