import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import BannerDefault from './BannerDefault'
import BannerLanding from './BannerLanding'
import BannerContact from './BannerContact'
import BannerTitle from './BannerTitle'
import BannerPost from './BannerPost'

const BannerShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    landing: BannerLanding,
    contact: BannerContact,
    title: BannerTitle,
    post: BannerPost
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default BannerShell
