import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaDefault({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section id="CtaDefault" className="relative">
      <div className="container-right">
        <div className="grid grid-cols-3 bg-metric-zangoose p-4 sm:px-12 sm:py-10">
          <div className="col-span-3 sm:col-span-2 self-center">
            <Content className="parsecontent-sm parsecontent-h-dark">{fields.description}</Content>
          </div>
          <div className="col-span-3 sm:col-span-1 mt-4 sm:mt-0">
            <LosseLink className="btn btn-metric-electrike w-full" to={fields.link?.url || '/'} target={fields?.link?.target || '_self'}>
              {fields.link?.title}
            </LosseLink>
          </div>
        </div>
      </div>
    </section>
  )
}
