import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Download from '~/components/svg/Download'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaDownload({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  const [status, setStatus] = useState('')

  // return <></>
  return (
    <section id="download" className="relative">
      <LossePlaatjie src={fields.background} maxwidth={2000} className="absolute top-0 bottom-0 left-0 w-full h-full" />
      <div className="container-right py-6 lg:py-0">
        <div className="relative grid grid-cols-2">
          <div className="col-span-2 sm:col-span-1">
            <LossePlaatjie src={fields.fileimage} maxwidth={1000} className="lg:-mt-28 sm:order-last" />
          </div>
          <div className="col-span-2 sm:col-span-1 self-center sm:order-first">
            {status !== 'done' && (
              <Content className="text-white parsecontent-md parsecontent-extrabold mb-4 children-p:text-xl">{fields.description}</Content>
            )}
            <Form
              scrollToConfirm={false}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setStatus(status)
                return (
                  <div>
                    <Content className="text-white children-headings:text-3xl">{content}</Content>
                  </div>
                )
              }}
              className="relative"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
