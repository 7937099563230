export default function ChevronDown({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={className}>
      <path
        fill="#000"
        fillOpacity="0.35"
        d="M12 15.5a1.992 1.992 0 01-1.414-.585L5.293 9.621l1.414-1.414L12 13.5l5.293-5.293 1.414 1.414-5.293 5.293A1.994 1.994 0 0112 15.5z"
      ></path>
    </svg>
  )
}
