export default function Email({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" className={className}>
      <g clipPath="url(#clip0_383_877)">
        <path
          fill="#5ACD8F"
          d="M16.625.875H4.375A4.38 4.38 0 000 5.25v10.5a4.38 4.38 0 004.375 4.375h12.25A4.38 4.38 0 0021 15.75V5.25A4.38 4.38 0 0016.625.875zm-12.25 1.75h12.25a2.625 2.625 0 012.433 1.651l-6.701 6.702a2.63 2.63 0 01-3.714 0l-6.7-6.702a2.625 2.625 0 012.432-1.651zm12.25 15.75H4.375A2.625 2.625 0 011.75 15.75V6.562l5.656 5.653a4.381 4.381 0 006.188 0l5.656-5.653v9.188a2.625 2.625 0 01-2.625 2.625z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_383_877">
          <path fill="#fff" d="M0 0H21V21H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
