export default function Whatsapp({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" fill="none" viewBox="0 0 47 47" className={className}>
      <path
        fill="#fff"
        d="M30.505 16.406a9.836 9.836 0 00-7.01-2.906c-5.464 0-9.91 4.446-9.91 9.91 0 1.746.455 3.452 1.321 4.956L13.5 33.5l5.255-1.38a9.88 9.88 0 004.736 1.206h.005c5.46 0 10.004-4.447 10.004-9.91 0-2.648-1.125-5.135-2.995-7.01zm-7.01 15.25A8.223 8.223 0 0119.3 30.51L19 30.33l-3.116.817.83-3.04-.196-.312a8.21 8.21 0 01-1.26-4.384c0-4.54 3.697-8.237 8.242-8.237 2.2 0 4.268.857 5.821 2.415 1.554 1.558 2.51 3.625 2.505 5.826 0 4.545-3.79 8.241-8.33 8.241zm4.518-6.17c-.245-.124-1.464-.723-1.692-.803-.227-.085-.392-.125-.558.125-.165.25-.638.804-.785.973-.143.165-.29.188-.536.063-1.455-.728-2.41-1.3-3.37-2.947-.255-.437.254-.406.727-1.352.08-.166.04-.308-.022-.433-.063-.125-.558-1.344-.764-1.84-.2-.482-.406-.415-.558-.424-.142-.009-.308-.009-.473-.009a.918.918 0 00-.66.308c-.228.25-.867.849-.867 2.067 0 1.219.889 2.398 1.01 2.563.124.165 1.745 2.665 4.231 3.74 1.572.68 2.188.738 2.974.621.477-.071 1.464-.598 1.67-1.178.205-.58.205-1.076.142-1.179-.058-.111-.223-.174-.469-.294zM23.5 46C11.074 46 1 35.926 1 23.5h-2C-1 37.031 9.969 48 23.5 48v-2zM46 23.5C46 35.926 35.926 46 23.5 46v2C37.031 48 48 37.031 48 23.5h-2zM23.5 1C35.926 1 46 11.074 46 23.5h2C48 9.969 37.031-1 23.5-1v2zm0-2C9.969-1-1 9.969-1 23.5h2C1 11.074 11.074 1 23.5 1v-2z"
      ></path>
    </svg>
  )
}
