import { type LoaderData, useLosseLayout, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import Logo from '~/components/svg/Logo'
import type { Component_Header, Maybe, WpPageInfo } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import HeaderTopmenu from './HeaderTopmenu'
import { motion } from 'framer-motion'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
  rest: {
    posts: {
      pageInfo?: Maybe<WpPageInfo>
    }
  }
}

export default function Header() {
  const blakaas = useLoaderData<HeaderLoaderData>()
  const totalPosts = blakaas.rest.posts.pageInfo?.total

  const { isSticky, isScrollable } = useLosseLayout()

  return (
    <nav
      className={`fixed left-0 top-0 right-0 z-[100] bg-transparent transition-all border-b-[1px] z- ${isSticky ? 'border-metric-shiny-magneton' : 'border-transparent'
        }`}
    >
      <div className="relative">
        <AnimatePresence>
          {!isSticky && (
            <motion.div className="pointer-events-none absolute top-0 left-0 right-0 3xl:left-[calc(50%-1100px)] w-full h-full lg:h-[125%] bg-gradient-to-b from-black via-black max-w-[2200px] " />
          )}
        </AnimatePresence>
        <div
          className={`relative z-[101] transition-colors ${isSticky || !isScrollable ? 'bg-metric-electrike md:bg-glass' : 'bg-transparent'}`}
        >
          <div className="container-xl">
            <HeaderTopmenu />
          </div>
        </div>
        <div className={`transition-colors ${isSticky ? 'bg-black md:bg-glass' : 'bg-transparent'}`}>
          <div className="container-xl flex h-full items-center justify-between">
            <LosseLink aria-label="Home" className="relative z-[99] lg:pl-5" to="/">
              <Logo />
            </LosseLink>
            <HeaderDesktop totalPosts={totalPosts || 10} />
            <HeaderMobile totalPosts={totalPosts || 10} />
          </div>
        </div>
      </div>
    </nav>
  )
}
