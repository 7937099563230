import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import GridDefault from './GridDefault'
import GridCrystals from './GridCrystals'
import GridTeammembers from './GridTeammembers'

export default function GridShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: GridDefault,
    crystals: GridCrystals,
    teammembers: GridTeammembers
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
