import { LosseLink, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import ContentstableGrid from '~/components/elements/ContentstableGrid'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { pathname } = useLocation()
  const { hasContentstable } = useLoaderData()

  return (
    <section id={fields.sectionid || 'TextDefault'} className="relative">
      <div className="container-right">
        <ContentstableGrid>
          <div className={`relative ${fields.containerwidth === 'small' ? 'xl:ml-44 xl:mr-20' : 'sm:ml-7'}`}>
            <div
              className={`absolute ${pathname === '/' ? 'h-[150%] sm:h-[170%]' : 'h-full'
                } w-[2px] right-0 sm:left-0 bottom-0 bg-metric-qwilfish hidden md:block`}
            />
            <Content
              className={clsx('parsecontent-sm pr-4 sm:py-14 sm:pl-10 xl:pl-20 children:mb-4', !hasContentstable && 'sm:pr-20 xl:w-3/4')}
            >
              {fields?.description}
            </Content>
          </div>
          <div className="flex flex-wrap items-center gap-4">
            {fields.link?.title && (
              <div className="mt-7 xl:ml-28 text-sm md:text-base w-full md:w-auto">
                <LosseLink className="btn btn-metric-electrike w-full md:w-auto" to={fields.link?.url || '/'}>
                  {fields.link?.title}
                </LosseLink>
              </div>
            )}
            {fields.linkTwo?.title && (
              <div className="md:mt-7 xl:ml-28 w-full md:w-auto text-sm md:text-base">
                <LosseLink className="btn btn-metric-electrike w-full md:w-auto" to={fields.linkTwo?.url || '/'}>
                  {fields.linkTwo?.title}
                </LosseLink>
              </div>
            )}
          </div>
        </ContentstableGrid>
      </div>
    </section>
  )
}
