export default function Phone({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" className={className}>
      <g clipPath="url(#clip0_383_879)">
        <path
          fill="#5ACD8F"
          d="M11.374.875A.875.875 0 0112.249 0a8.76 8.76 0 018.75 8.75.875.875 0 01-1.75 0 7.008 7.008 0 00-7-7 .875.875 0 01-.875-.875zm.875 4.375a3.5 3.5 0 013.5 3.5.875.875 0 001.75 0 5.256 5.256 0 00-5.25-5.25.875.875 0 100 1.75zm7.956 9.397a2.713 2.713 0 010 3.83l-.796.918C12.243 26.256-5.196 8.822 1.56 1.633L2.565.758a2.696 2.696 0 013.786.035C6.38.82 8 2.926 8 2.926a2.713 2.713 0 01-.006 3.747L6.98 7.947a11.184 11.184 0 006.064 6.077l1.282-1.02A2.712 2.712 0 0118.073 13s2.105 1.62 2.132 1.648zm-1.204 1.272s-2.094-1.61-2.12-1.638a.963.963 0 00-1.356 0c-.024.024-1.789 1.43-1.789 1.43a.875.875 0 01-.856.134 13.133 13.133 0 01-7.718-7.707.875.875 0 01.126-.875s1.406-1.766 1.43-1.789a.963.963 0 000-1.355C6.691 4.092 5.08 1.997 5.08 1.997a.963.963 0 00-1.321.034l-1.006.875C-2.184 8.842 12.928 23.116 18.13 18.2l.797-.919a.98.98 0 00.074-1.362z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_383_879">
          <path fill="#fff" d="M0 0H21V21H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
