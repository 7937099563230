export default function DiamondLeft({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="209" height="362" fill="none" viewBox="0 0 209 362" className={className}>
      <g clipPath="url(#clip0_512_837)">
        <path fill="#fff" d="M208.88 120.18L0 0v240.88l208.88 120.6v-241.3z"></path>
      </g>
      <defs>
        <clipPath id="clip0_512_837">
          <path fill="#fff" d="M0 0H208.88V361.48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
