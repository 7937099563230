import { type LoaderData, useLoaderData, useLocation, type LoaderFunction, json, wordpressLoader } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import type { Page_Flexcontent_Flex_Contentstable } from '~/graphql/types'

export const loader: LoaderFunction = async (data) => {
  const page = await wordpressLoader(data)

  let hasContentstable = false

  page.body.page.flexContent.flex.forEach((item: Page_Flexcontent_Flex_Contentstable) => {
    if (item.fieldGroupName === 'page_Flexcontent_Flex_Contentstable') {
      hasContentstable = true
    }
  })

  page.body.hasContentstable = hasContentstable

  return json(page.body, page.headers)
}

export default function $page() {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      document.getElementById(hash.replace('/#S+/g', ''))?.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [hash])

  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex} />
    </Layout>
  )
}
