import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderDefault({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section id="SliderDefault" className="relative">
      <div className="container-right py-16">
        <div className="grid grid-cols-5 gap-x-6">
          <div className="col-span-5 sm:col-span-2 mb-5 sm:mb-0">
            <Content className="parsecontent-sm opacity-30">{fields.description}</Content>
          </div>
          <div className="col-span-5 sm:col-span-3">
            <Slider
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 10
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15
                }
              }}
            >
              {fields.slides?.map((slide, index) => (
                <Slide key={index} className="!h-auto flex items-center">
                  <LosseLink
                    to={slide?.link?.url || '/'}
                    className="hover:opacity-80 transition-opacity"
                    target={slide?.link?.target || '_self'}
                  >
                    <LossePlaatjie src={slide?.image} />
                  </LosseLink>
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
