import { useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function ContentstableGrid({ children, className }: { children: React.ReactNode, className?: string }) {
  const { hasContentstable } = useLoaderData()

  return (
    <div className={clsx(className && className, 'lg:grid grid-cols-12 gap-10 xl:gap-20')}>
      <div className={clsx(hasContentstable ? 'lg:col-span-8 xl:col-span-9' : 'lg:col-span-12')}>{children}</div>
    </div>
  )
}
